import { ACTIONS } from "./../constants";

const initialState = {
  trainers: [],
  trainer: {},
  trainerExtraData: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted:false
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_TRAINER: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_TRAINERS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_TRAINERS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        trainers: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAINERS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_TRAINER_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_TRAINER_SUCCEDED: {
      return {
        ...state,
        trainerContent: payload,
        trainer: payload,
        success: { ...state.success, isUpdated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_TRAINER_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }



    case ACTIONS.DELETE_TRAINER_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.DELETE_TRAINER_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isDeleted: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.DELETE_TRAINER_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }
   
   
   
    case ACTIONS.CREATE_TRAINER_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_TRAINER_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true },
       isLoading: false, error: null }
    }
    case ACTIONS.CREATE_TRAINER_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }
   
    
   
    case ACTIONS.FETCH_TRAINER_INIT: {
      return { ...state, isFetching: true, trainer: null, error: null };
    }
    case ACTIONS.FETCH_TRAINER_SUCCEDED: {
      return {
        ...state,
        trainer: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAINER_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }

    case ACTIONS.FETCH_FETCH_TRAINER_EXTRA_DATA_INIT: {
      return {
        ...state,
        isFetching: true,
        trainer: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAINER_EXTRA_DATA_SUCCEDED: {
      return {
        ...state,
        trainer: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAINER_EXTRA_DATA_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }

    default: {
      return state;
    }
  }
};
