import _ from "lodash";
import * as Yup from "yup"
import { INPUT, SELECT, UPLOAD } from "src/components/partials";
import { categoryUIHelper } from "src/modules/admin/UIHelpers/specialtie/categoryUIHelper";

// Validation schema
export const productfieldes = ({ intl }) => [
  {
    name: "title",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.QUESTION" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_QUESTION" }),
    size: 12,
    validation: Yup.string().required(),
    required: true,
  },
  {
    name: "description",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.string().required(),
    required: true,
  },
  {
    name: "price",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.number().required(),
    required: true,
  },
  {
    name: "duration",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.number().required(),
    required: true,
  },
  {
    name: "language",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.number().required(),
    required: true,
  },
  {
    name: "published_date",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.number().required(),
    required: true,
  },
  {
    name: "level",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.number().required(),
    required: true,
  },
  {
    name: "image",
    component: UPLOAD,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.string().required(),
    required: true,
  },
  {
    name: "documents",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.number().required(),
    required: true,
  },
  

]
