import { ACTIONS } from "./../constants";

const initialState = {
  users: [],
  user: {},
  UserExtraData: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted:false,
    isexported:false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_USER: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_USERS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USERS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        users: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_USERS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_USER_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_USER_SUCCEDED: {
      return {
        ...state,
        UserContent: payload,
        user: payload,
        success: { ...state.success, isUpdated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_USER_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    case ACTIONS.DELETE_USER_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.DELETE_USER_SUCCEDED: {
      const{id}=payload
      return {
        ...state,
        users:state.users.filter((user)=>user.id!=id),
        success: { ...state.success, isDeleted: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.DELETE_USER_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }


    case ACTIONS.CREATE_USER_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_USER_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_USER_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }

    case ACTIONS.EXPORT_USER_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EXPORT_USER_SUCCEDED: {
      return { ...state, success: { ...state.success, isexported: true }, isLoading: false, error: null }
    }
    case ACTIONS.EXPORT_USER_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }
  

    case ACTIONS.FETCH_USER_INIT: {
      return { ...state, isFetching: true, user: null, error: null };
    }
    case ACTIONS.FETCH_USER_SUCCEDED: {
      return {
        ...state,
        user: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_USER_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }

   
   
    

    case ACTIONS.FETCH_USER_EXTRA_DATA_INIT: {
      return {
        ...state,
        isFetching: true,
        USERExtraData: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_USER_EXTRA_DATA_SUCCEDED: {
      return {
        ...state,
        USERExtraData: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_USER_EXTRA_DATA_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }

    default: {
      return state;
    }
  }
};
