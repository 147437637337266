import { MODULES_PERMISSIONS } from "src/constants";
import { lazy } from "react";
import { CREATE,VIEW,UPDATE,ACTIVATE } from "src/constants";
import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"

const UserShow = lazy(() => import("../UserShow"));
const UserEdit = lazy(() => import("../UserEdit"));
const UserPage = lazy(() => import("../UserPage"));
const Usernew = lazy(() => import("../Usernew"));
const Userdisplay = lazy(()=> import("../components/display/Userdisplay"))

const { LIST_REFERENTIEL } = MODULES_PERMISSIONS;
export const userEdit = {
  path: "/user/:param/edit",
  component: UserEdit,
  can: LIST_REFERENTIEL.permissions[UPDATE],
};
export const userShow = {
  path: "/user/:param/show",
  component: UserShow,
  can: LIST_REFERENTIEL.permissions[VIEW]
}

export const listUsers = {
  path: "/users",
  component: UserPage,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
export const userCreate = {
  path: "/newUser",
  component: Usernew,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
export const userDisplay = {
  path: "/lists",
  component: Userdisplay,
  can: LIST_REFERENTIEL.permissions[VIEW]
}
const path = routes.admin.path

export default combinePathRoutes({ path }, {
  listUsers,
  userCreate,
  userShow,
  userDisplay,
  userEdit,
  // listCreate,
  // listEdit,
  // listShow,
  // listForm,
  // listDisplay
})