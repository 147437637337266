import { ACTIONS, ENDPOINTS } from "../constants"
import { CALL_API, HTTP_METHODS } from "../../../../../../constants"
import { DELETE_DELETE_FAILED, DELETE_DELETE_SUCCEDED } from "../constants/actions";


export const clearStore = () =>
({
  type: ACTIONS.CLEAR_FAQS
})


export const fetchproducts = ( param ) => ({
  type: CALL_API,
  
  meta: {
    actions: {
      init: ACTIONS.FETCH_PRODUCTS_INIT,
      success: ACTIONS.FETCH_PRODUCTS_SUCCEDED,
      fail: ACTIONS.FETCH_PRODUCTS_FAILED
    },
    //isFormData: true,
    endpoint: ENDPOINTS.PRODUCTS,
    method: HTTP_METHODS.GET,
    auth: true,
  },
});


export const fetchproduct = ( param ) => ({
  type: CALL_API,
  
  meta: {
    actions: {
      init: ACTIONS.FETCH_PRODUCT_INIT,
      success: ACTIONS.FETCH_PRODUCT_SUCCEDED,
      fail: ACTIONS.FETCH_PRODUCT_FAILED
    },
    //isFormData: true,
    endpoint: ENDPOINTS.PRODUCT.replace(":param", param),
    method: HTTP_METHODS.GET,
    auth: true,
  },
});

export const createProduct = (payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_PRODUCT_INIT,
      success: ACTIONS.CREATE_PRODUCT_SUCCEDED,
      fail: ACTIONS.CREATE_PRODUCT_FAILED
    },
    endpoint: ENDPOINTS.PRODUCT_CREATE,
    method: HTTP_METHODS.POST,
    auth: true
  }
})
export const uploadFile = (payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.UPLOAD_FILE_INIT,
        success: ACTIONS.UPLOAD_FILE_SUCCEDED,
        fail: ACTIONS.UPLOAD_FILE_FAILED
      },
      endpoint: ENDPOINTS.IMAGE_PRODUCT_UPLOAD,
      method: HTTP_METHODS.POST,
      auth: true
    }
  })


  export const uploadVideo = (payload,id) => {
    // Convert payload to FormData
    const formData = new FormData();
    formData.append('duration', payload.duration);
    formData.append('file', payload.file);
    formData.append('title', payload.title);
    formData.append('product_id', id);
    console.log(formData)
    // Additional fields can be appended as necessary

    return {
        type: CALL_API,
        payload: formData,  // send formData instead of JSON payload
        meta: {
            actions: {
                init: ACTIONS.UPLOAD_VIDEO_INIT,
                success: ACTIONS.UPLOAD_VIDEO_SUCCEDED,
                fail: ACTIONS.UPLOAD_VIDEO_FAILED
            },
            endpoint: ENDPOINTS.VIDEO,
            method: HTTP_METHODS.POST,
            headers: {
                'Accept': 'application/json',  // Only include Accept header
                // Do not set 'Content-Type': 'multipart/form-data' here
            },
            auth: true
        }
    };
};
export const uploadDocument = (payload,id) => {
  // Convert payload to FormData
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('title', payload.title);
  formData.append('product_id', id);
  console.log(formData)
  // Additional fields can be appended as necessary

  return {
      type: CALL_API,
      payload: formData,  // send formData instead of JSON payload
      meta: {
          actions: {
              init: ACTIONS.UPLOAD_DOCUMENT_INIT,
              success: ACTIONS.UPLOAD_DOCUMENT_SUCCEDED,
              fail: ACTIONS.UPLOAD_DOCUMENT_FAILED
          },
          endpoint: ENDPOINTS.DOCUMENT ,
          method: HTTP_METHODS.POST,
          headers: {
              'Accept': 'application/json',  // Only include Accept header
              // Do not set 'Content-Type': 'multipart/form-data' here
          },
          auth: true
      }
  };
};



export const EditFaq = ( param , payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.EDIT_FAQS_INIT,
        success: ACTIONS.EDIT_FAQS_SUCCEDED,
        fail: ACTIONS.EDIT_FAQS_FAILED
      },
      endpoint: ENDPOINTS.FAQS_UPDATE.replace(":param", param),
      method: HTTP_METHODS.PATCH,
      auth: true
    }
  })
  export const fetchExtra_Data_FAQs = ( param ) => ({
    type: CALL_API,
    
    meta: {
      actions: {
        init: ACTIONS.FETCH_FAQ_INIT,
        success: ACTIONS.FETCH_FAQ_SUCCEDED,
        fail: ACTIONS.FETCH_FAQ_FAILED
      },
      //isFormData: true,
      endpoint: ENDPOINTS.FAQ.replace(":param", param),
      method: HTTP_METHODS.GET,
      auth: true,
    },
  });


  export const deletevideo = ({ param }, payload) =>
    ({
      type: CALL_API,
      payload,
      meta: {
        actions: {
          init: ACTIONS.DELETE_VIDEO_INIT,
          success: ACTIONS.DELETE_VIDEO_SUCCEDED,
          fail: ACTIONS.DELETE_VIDEO_FAILED,
        },
        endpoint: ENDPOINTS.DELETE_VD.replace(":param", param),
        method: HTTP_METHODS.DELETE,
        auth: true
      }
    })