import { CircularProgress } from "@mui/material"
import React, {useEffect, useState} from "react"
import {Portal} from "react-portal"

const animationStartClass = "dialog-shown"
const animationEndClass = "dialog-hidden"
const initCssClasses = [
  "dialog",
  "dialog-default",
  "dialog-loader",
  "dialog-top-center"
]

const LoadingDialog = ({ isLoading, text }) => {
  const [cssClasses, setCssClasses] = useState(initCssClasses)

  useEffect(() => {
    // for animation start should toggle 'dialog-hidden' css class
    const cssClassForAdding = isLoading
      ? animationStartClass
      : animationEndClass
    setCssClasses([...initCssClasses, cssClassForAdding])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <Portal node={document && document.getElementById("layout-portal")}>
      <div className={cssClasses.join(" ")}>
     <div className="d-flex flex-column align-items-center justify-content-center gaps-6 flex-wrap">
     <CircularProgress size={20} thickness={5} className="mr-4" disableShrink color="error" />
      <div className="">
      {text}
      </div>
     </div>
     </div>
    </Portal>
  )
}


export default LoadingDialog
