import { MODULES_PERMISSIONS } from "src/constants";
import { lazy } from "react";
import { CREATE,VIEW,UPDATE,ACTIVATE } from "src/constants";
import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"


const Studentnew = lazy(() => import("../Studentnew"));
const StudentEdit = lazy(() => import("../StudentEdit"));
const StudentPage = lazy(() => import("../StudentPage"));
const StudentShow = lazy(() => import("../StudentShow"));


const { LIST_REFERENTIEL } = MODULES_PERMISSIONS;
export const Studentedit = {
  path: "/student/:param/edit",
  component: StudentEdit,
  can: LIST_REFERENTIEL.permissions[UPDATE],
};
export const Studentshow = {
  path: "/student/:param/show",
  component: StudentShow,
  can: LIST_REFERENTIEL.permissions[VIEW]
}

export const StudentsPage = {
  path: "/students",
  component: StudentPage,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
export const StudentCreate = {
  path: "/student/new",
  component: Studentnew,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
// export const userDisplay = {
//   path: "/lists",
//   component: Userdisplay,
//   can: LIST_REFERENTIEL.permissions[VIEW]
// }
const path = routes.admin.path

export default combinePathRoutes({ path }, {
  StudentsPage,
  StudentCreate,
  Studentedit,
  Studentshow
  // listShow,
  // listCreate,
  // listEdit,
  
  // listForm,
  // listDisplay
})