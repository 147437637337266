import _ from "lodash";
import * as Yup from "yup"
import { INPUT, SELECT } from "src/components/partials";
import { categoryUIHelper } from "src/modules/admin/UIHelpers/specialtie/categoryUIHelper";

// Validation schema
export const FAQfields = ({ intl }) => [
  {
    name: "question",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.QUESTION" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_QUESTION" }),
    size: 12,
    validation: Yup.string().required(),
    required: true,
  },
  {
    name: "answer",
    component: INPUT,
    label: intl.formatMessage({ id: "FAQ.INPUT.ANSWER" }),
    placeholder: intl.formatMessage({ id: "FAQ.INPUT_Write_ANSWER" }),
    size: 12,
    validation: Yup.string().required(),
    required: true,
  },
  

]
