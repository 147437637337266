import { ACTIONS } from "./../constants";

const initialState = {
  students: [],
  student: {},
  StudentExtraData: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_STUDENT: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_STUDENTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_STUDENTS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        students: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_STUDENTS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_STUDENT_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_STUDENT_SUCCEDED: {
      return {
        ...state,
        STUDENTContent: payload,
        student: payload,
        success: { ...state.success, isUpdated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_STUDENT_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

   
   
   
    case ACTIONS.CREATE_STUDENT_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_STUDENT_SUCCEDED: {
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null }
    }
    case ACTIONS.CREATE_STUDENT_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }
   
    
   
    case ACTIONS.FETCH_STUDENT_INIT: {
      return { ...state, isFetching: true, student: null, error: null };
    }
    case ACTIONS.FETCH_STUDENT_SUCCEDED: {
      return {
        ...state,
        student: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_STUDENT_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }

    case ACTIONS.FETCH_FETCH_STUDENT_EXTRA_DATA_INIT: {
      return {
        ...state,
        isFetching: true,
        student: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_STUDENT_EXTRA_DATA_SUCCEDED: {
      return {
        ...state,
        student: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_STUDENT_EXTRA_DATA_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }
    case ACTIONS.DELETE_STUDENT_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.DELETE_STUDENT_SUCCEDED: {
      return {
        ...state,
        success: { ...state.success, isDeleted: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.DELETE_STUDENT_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }

    default: {
      return state;
    }
  }
};
