import { pages as homeRoutes } from './containers/home/routes'
import { pages as profileRoutes } from './containers/profile/routes'

import { pages as specialtiesRoutes } from './containers/specialties/routes'

import { pages as listRoutes } from './containers/list/routes'
import {pages as DCIRoutes} from './containers/dci/routes'
import {pages as usersRoutes} from './containers/user/routes'
import {pages as studentRoutes} from './containers/student/routes'
import {pages as trainersRoutes} from './containers/trainer/routes'
import {pages as trainigsRoutes} from './containers/training/routes'
import {pages as faqRoutes} from './containers/FAQ/routes'
import {pages as ProductRoutes} from './containers/product/routes'
import {pages as CalendarRoutes} from './containers/calendar/routes'
// Combined routes
export default {
  ...homeRoutes,
  ...profileRoutes,
  ...DCIRoutes,
  ...listRoutes,
  ...specialtiesRoutes,
  ...usersRoutes,
  ...studentRoutes,
  ...trainersRoutes,
  ...trainigsRoutes,
  ...faqRoutes,
  ...ProductRoutes,
  ...CalendarRoutes



}
