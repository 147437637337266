// list
export const CLEAR_TRAINING = "TRAINING@CLEAR_TRAINING";

export const FETCH_TRAININGS_INIT = "list@FETCH_TRAININGS_INIT";
export const FETCH_TRAININGS_SUCCEDED = "list@FETCH_TRAININGS_SUCCEDED";
export const FETCH_TRAININGS_FAILED = "list@FETCH_TRAININGS_FAILED";


export const FETCH_TRAININGS_STUDENTS_INIT = "list@FETCH_TRAININGS_STUDENTS_INIT";
export const FETCH_TRAININGS_STUDENTS_SUCCEDED = "list@FETCH_TRAININGS_STUDENTS_SUCCEDED";
export const FETCH_TRAININGS_STUDENTS_FAILED = "list@FETCH_TRAININGS_STUDENTS_FAILED";


export const FETCH_TRAININGS_MATCH_STUDENTS_INIT = "list@FETCH_TRAININGS_MATCH_STUDENTS_INIT";
export const FETCH_TRAININGS_MATCH_STUDENTS_SUCCEDED = "list@FETCH_TRAININGS_MATCH_STUDENTS_SUCCEDED";
export const FETCH_TRAININGS_MATCH_STUDENTS_FAILED = "list@FETCH_TRAININGS_MATCH_STUDENTS_FAILED";

export const CREATE_ASIGN_INIT = "ASIGN@CREATE_ASIGN_INIT";
export const CREATE_ASIGN_SUCCEDED = "ASIGN@CREATE_ASIGN_SUCCEDED";
export const CREATE_ASIGN_FAILED = "ASIGN@CREATE_TRAINING_FAILED";

export const CREATE_TRAINING_INIT = "TRAINING@CREATE_TRAINING_INIT";
export const CREATE_TRAINING_SUCCEDED = "TRAINING@CREATE_TRAINING_SUCCEDED";
export const CREATE_TRAINING_FAILED = "TRAINING@CREATE_TRAINING_FAILED";

export const FETCH_TRAINING_INIT = "TRAINING@FETCH_TRAINING_INIT";
export const FETCH_TRAINING_SUCCEDED = "TRAINING@FETCH_TRAINING_SUCCEDED";
export const FETCH_TRAINING_FAILED = "TRAINING@FETCH_TRAINING_FAILED";


export const EDIT_TRAINING_INIT = "TRAINING@EDIT_TRAINING_INIT";
export const EDIT_TRAINING_SUCCEDED = "TRAINING@EDIT_TRAINING_SUCCEDED";
export const EDIT_TRAINING_FAILED = "TRAINING@EDIT_TRAINING_FAILED";


export const FETCH_FETCH_TRAINING_EXTRA_DATA_INIT = "TRAINING@FETCH_TRAINING_INIT";
export const FETCH_TRAINING_EXTRA_DATA_SUCCEDED = "TRAINING@FETCH_TRAINING_SUCCEDED";
export const FETCH_TRAINING_EXTRA_DATA_FAILED = "TRAINING@FETCH_TRAINING_FAILED";


export const CLEAR_PROGRAM = "program@CLEAR_PROGRAM";

export const EDIT_PROGRAM_INIT = "program@EDIT_PROGRAM_INIT";
export const EDIT_PROGRAM_SUCCEEDED = "program@EDIT_PROGRAM_SUCCEEDED";
export const EDIT_PROGRAM_FAILED = "program@EDIT_PROGRAM_FAILED";

export const FETCH_PROGRAM_INIT = "program@FETCH_PROGRAM_INIT";
export const FETCH_PROGRAM_SUCCEEDED = "program@FETCH_PROGRAM_SUCCEEDED";
export const FETCH_PROGRAM_FAILED = "program@FETCH_PROGRAM_FAILED";

export const FETCH_FETCH_SESSION_TRAINING_INIT = "SESSION_TRAINING@FETCH_SESSION_TRAINING_INIT";
export const FETCH_SESSION_TRAINING_SUCCEDED = "SESSION_TRAINING@FETCH_SESSION_TRAINING_SUCCEDED";
export const FETCH_SESSION_TRAINING_FAILED = "SESSION_TRAINING@FETCH_SESSION_TRAINING_FAILED";