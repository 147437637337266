import { MODULES_PERMISSIONS } from "src/constants";
import { lazy } from "react";
import { CREATE,VIEW,UPDATE,ACTIVATE } from "src/constants";
import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"


const Trainernew = lazy(() => import("../Trainernew"));
const TrainerEdit = lazy(() => import("../TrainerEdit"));
const TrainerPage = lazy(() => import("../TrainerPage"));
const TrainerShow = lazy(() => import("../TrainerShow"));


const { LIST_REFERENTIEL } = MODULES_PERMISSIONS;
export const TrianersPage = {
  path: "/trainers",
  component: TrainerPage,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
export const TrainersEdit = {
  path: "/trainer/:param/edit",
  component: TrainerEdit,
  can: LIST_REFERENTIEL.permissions[UPDATE],
};
export const Trainershow = {
  path: "/trainer/:param/show",
  component: TrainerShow,
  can: LIST_REFERENTIEL.permissions[VIEW]
}


export const TrainerCreate = {
  path: "/trainer/new",
  component: Trainernew,
  can: LIST_REFERENTIEL.permissions[VIEW],
};

const path = routes.admin.path

export default combinePathRoutes({ path }, {
  TrianersPage,
  Trainershow,
  TrainerCreate,
  TrainersEdit,
  
})