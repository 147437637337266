import { ACTIONS } from "./../constants";

const initialState = {
  sessionsTrainig:[],
  students:[],
  match:[],
  matchedStudent:[],
  trainings: [],
  training: {},
  trainingExtraData: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
  },
  error: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_TRAINING: {
      return {
        ...state,
        success: initialState.success,
        error: null,
        isFetching: false,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_TRAININGS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_TRAININGS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        trainings: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAININGS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.FETCH_TRAININGS_STUDENTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_TRAININGS_STUDENTS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        totalSize: count,
        students: results,
        matchedStudent:[...results.filter(student=> state.match.some(item=>item.idStudent === student.id))],
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAININGS_MATCH_STUDENTS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.FETCH_TRAININGS_MATCH_STUDENTS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_TRAININGS_MATCH_STUDENTS_SUCCEDED: {
     
      return {
        ...state,
        match: payload,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAININGS_STUDENTS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_TRAINING_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.EDIT_TRAINING_SUCCEDED: {
      return {
        ...state,
        trainingContent: payload,
        training: payload,
        success: { ...state.success, isUpdated: true },
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_TRAINING_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false };
    }


    

   
   
   
    case ACTIONS.CREATE_ASIGN_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_ASIGN_SUCCEDED: {
      const {idStudent} = payload
      return { ...state, success: { ...state.success, isCreated: true }, isLoading: false, error: null,
      matchedStudent: state.matchedStudent.concat(state.students.find((student)=> student.id === idStudent)),
      students: [...state.students.filter((student)=> student.id !== idStudent)]
    }
    }
    case ACTIONS.CREATE_ASIGN_FAILED: {
      return { ...state, error: payload, isLoading: false, success: false }
    }
   
    
   
    case ACTIONS.FETCH_TRAINING_INIT: {
      return { ...state, isFetching: true, training: null, error: null };
    }
    case ACTIONS.FETCH_TRAINING_SUCCEDED: {
      return {
        ...state,
        training: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAINING_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }

    case ACTIONS.FETCH_FETCH_TRAINING_EXTRA_DATA_INIT: {
      return {
        ...state,
        isFetching: true,
        training: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAINING_EXTRA_DATA_SUCCEDED: {
      return {
        ...state,
        training: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_TRAINING_EXTRA_DATA_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }
    case ACTIONS.FETCH_SESSION_TRAINING_INIT: {
      return {
        ...state,
        isFetching: true,
        training: null,
        error: null,
      };
    }
    case ACTIONS.FETCH_SESSION_TRAINING_SUCCEDED: {
      return {
        ...state,
        sessionsTrainig: payload,
        isLoading: false,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_SESSION_TRAINING_FAILED: {
      return { ...state, isFetching: false, isLoading: false, error: payload };
    }

     

    case ACTIONS.CLEAR_PROGRAM:
      return { ...state, success: initialState.success, error: null, isFetching: false };

    
    case ACTIONS.EDIT_PROGRAM_INIT:
      return { ...state, isFetching: true, error: null };
    case ACTIONS.EDIT_PROGRAM_SUCCEEDED:
      return { ...state, training: payload, success: true, error: null, isFetching: false };
    case ACTIONS.EDIT_PROGRAM_FAILED:
      return { ...state, isFetching: false, error: payload };

    
    case ACTIONS.FETCH_PROGRAM_INIT:
      return { ...state, isFetching: true, error: null };
    case ACTIONS.FETCH_PROGRAM_SUCCEEDED:
      return { ...state, training: payload, success: true, error: null, isFetching: false };
    case ACTIONS.FETCH_PROGRAM_FAILED:
      return { ...state, isFetching: false, error: payload };


    default: {
      return state;
    }
  }
};
