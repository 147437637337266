import { lazy } from "react"
import { VIEW } from "src/constants";
import { DASHBOARD } from "src/constants/AppPermissions";
import { VaccinationManagement } from "src/constants/ModulesPermissions";
import { combinePathRoutes } from "src/helpers"
// import ResetPhoneNumber from "src/modules/authentication/containers/ResetPhoneNumber";
import routes from "src/routes"

const CalendarPage = lazy(() => import("../CalendarPage"))


const calendar = {
  path: "/calendar",
  component: CalendarPage,
  exact: true,
  can: VaccinationManagement.module[DASHBOARD].permissions[VIEW]
}



const path = routes.admin.path
export default combinePathRoutes({ path }, {
  calendar,
  
})
