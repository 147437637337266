import React, {useMemo} from "react"
import objectPath from "object-path"
import SVG from "react-inlinesvg"
import {toAbsoluteUrl} from "../../../helpers"
import {useHtmlClassService} from "../core/MetronicLayout"
import LanguageSelectorDropdown from "../extras/LanguageSelectorDropdown"
import { FormattedMessage } from "react-intl"
import { shallowEqual, useSelector } from "react-redux"
import { isRLTLang } from "src/i18n"

const HeaderMobile = () => {

  const {  currentCenter,isSuperuser,isDssb } = useSelector(
    (state) => ({
      currentCenter: state.common.auth.currentUser.structureDetails,
      isSuperuser : state.common.auth.isSuperuser,
      isDssb : state.common.auth.isDssb

    }),
    shallowEqual
  );

  const uiService = useHtmlClassService()
  const welcomStyle= {fontFamily:"Poppins",fontWeight:"500",fontSize:"1rem!important",fontStyle:"normal",lineHeight:'1.5rem',color:'#303030'} 
  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    }
  }, [uiService])

  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >

          <div className="d-flex align-items-center">
            {layoutProps.asideDisplay && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <button className="btn p-0 burger-icon ml-4" id="kt_aside_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}

            {layoutProps.headerMenuSelfDisplay && (
                <>
                  {/*begin::Header Menu Mobile Toggle*/}
                  {/* <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">
                    <span/>
                  </button> */}
                  {/*end::Header Menu Mobile Toggle*/}
                </>
            )}

            {/*begin::Topbar Mobile Toggle*/}
            <button
                className="btn btn-hover-text-primary p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
                >
            <div className="btn btn-icon btn-clean h-40px w-40px btn-dropdown pulse pulse-primary "
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
              </span>
              <span className="pulse-ring"></span>
              </div>
            </button>
            {/* <button
                className="btn btn-hover-text-primary p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
            </button> */}
            {/*end::Topbar Mobile Toggle*/}
          </div>

          {/*begin::Toolbar*/}
          {/*begin::Logo*/}
          
          <div style={welcomStyle}><FormattedMessage id = {"GENERAL.WELCOME"}/> </div>
          {/*end::Logo*/}
          {/* <div className="btn p-0 mr-4">
           <SVG  src={toAbsoluteUrl("/media/svg/notificationBell.svg")}/>
         </div> */}
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  )
}


export default HeaderMobile
