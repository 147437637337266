import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "src/helpers";
import _ from "lodash";
const LayoutHeader = ({ goTo, avatarPath, title, buttonText, className }) => {
  return (
    <div
      className={`d-flex flex-column align-items-start ${className}`}
      style={{ padding: "0rem", gap: ".5rem" }}
    >
      {title && (
        <p
          style={{
            color: "#29366A",
            fontSize: "1.5rem",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "2.25rem",
          }}
        >
          {title}
        </p>
      )}
      {_.isFunction(goTo) && (
        <div
          className={`${className}`}
          style={{
            display: "flex",
            gap: ".5rem",
            aligItems: "center",
            cursor: "pointer",
            padding: ".75rem 1.25rem",
            justifyContent: "center",
            flexDirection: "row",
            borderRadius: ".25rem",
          }}
          onClick={goTo}
        >
          <span className="svg-icon-primary">
       {     avatarPath ?
            <SVG
              style={{ width: "1.5rem", height: "1.5rem" }}
              src={toAbsoluteUrl(avatarPath)}
            />
            :
            <SVG
              style={{ width: "1.125rem", height: "1.125rem" }}
              src={toAbsoluteUrl("/media/svg/VectoraddBtnSvg.svg")}
            />}
          </span>
          <p
            style={{
              color: "rgb(71, 21, 187)",
              fontSize: "1rem",
              textDecorationLine: "underline",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "1.5rem",
            }}
          >
            {buttonText}
          </p>
        </div>
      )}
    </div>
  );
};
export default LayoutHeader;
