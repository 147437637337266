import React, { useState } from 'react'
import Tab from './Tab';
import './tabStyle.scss'

const Tabs = ({ children,index,setCurrentTab=null}) => {
  const[activeTab,setActiveTab] = useState(children[index].props.label)

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
    if (setCurrentTab) setCurrentTab(...children.map((element,index)=>{
     return element.props.label === tab ? index : null
    }).filter((element)=> element!= null));
  }
    
  return (
    <div className="tabs">
    <div className="tab-list">
      {children.map((child) => {
        const { label } = child.props;

        return (
          <Tab
            activeTab={activeTab}
            key={label}
            label={label}
            onClick={onClickTabItem}
          />
        );
      })}
    </div>
    <div className="tab-content">
      {children.map((child) => {
        if (child.props.label !== activeTab) return undefined;
        return child.props.children;
      })}
    </div>
  </div>  )
}

export default Tabs