import { MODULES_PERMISSIONS } from "src/constants";
import { lazy } from "react";
import { CREATE,VIEW,UPDATE,ACTIVATE } from "src/constants";
import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"
import ListForm from "../components/from/ListForm";
import ListDisplay from "../components/display/ListDisplay";
const ListShow = lazy(() => import("../ListShow"))
const ListPage = lazy(() => import("../ListPage"));
const List = lazy(() => import("../ListNew"));
const ListEdit = lazy(()=> import("../ListEdit"))

const { LIST_REFERENTIEL } = MODULES_PERMISSIONS;

export const listCreate = {
  path: "/lists/new",
  component: List,
  can: LIST_REFERENTIEL.permissions[CREATE]
}

export const listEdit = {
  path: "/lists/:param/edit",
  component: ListEdit,
  can: LIST_REFERENTIEL.permissions[UPDATE],
};

export const listShow = {
  path: "/lists/:param/show",
  component: ListShow,
  can: LIST_REFERENTIEL.permissions[VIEW]
}

export const listList = {
  path: "/listes",
  component: ListPage,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
export const listForm = {
  path: "/list",
  component: ListForm,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
export const listDisplay = {
  path: "/lists",
  component: ListDisplay,
  can: LIST_REFERENTIEL.permissions[VIEW]
}
const path = routes.admin.path

export default combinePathRoutes({ path }, {
  listList,
  listCreate,
  listEdit,
  listShow,
  listForm,
  listDisplay
})