// structure
export const FETCH_VACCINATION_COUNT_INIT = "VACCINATION_COUNT@FETCH_VACCINATION_COUNT_INIT"
export const FETCH_VACCINATION_COUNT_SUCCEDED = "VACCINATION_COUNT@FETCH_VACCINATION_COUNT_SUCCEDED"
export const FETCH_VACCINATION_COUNT_FAILED = "VACCINATION_COUNT@FETCH_VACCINATION_COUNT_FAILED"

export const FETCH_STUDENTS_INIT = "list@FETCH_STUDENTS_INIT";
export const FETCH_STUDENTS_SUCCEDED = "list@FETCH_STUDENTS_SUCCEDED";
export const FETCH_STUDENTS_FAILED = "list@FETCH_STUDENTS_FAILED";

export const FETCH_TRAINERS_INIT = "list@FETCH_TRAINERS_INIT";
export const FETCH_TRAINERS_SUCCEDED = "list@FETCH_TRAINERS_SUCCEDED";
export const FETCH_TRAINERS_FAILED = "list@FETCH_TRAINERS_FAILED";

export const FETCH_USERS_INIT = "list@FETCH_USERS_INIT";
export const FETCH_USERS_SUCCEDED = "list@FETCH_USERS_SUCCEDED";
export const FETCH_USERS_FAILED = "list@FETCH_USERS_FAILED";

export const FETCH_CERTIFE_INIT = "list@FETCH_CERTIFE_INIT";
export const FETCH_CERTIFE_SUCCEDED = "list@FETCH_CERTIFE_SUCCEDED";
export const FETCH_CERTIFE_FAILED = "list@FETCH_CERTIFE_FAILED";
