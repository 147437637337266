import React, { useEffect, useMemo } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useDCIUIContext } from "../../context/DCIUIContext"
import { fetchDcis, deactivateDci } from "../../store/actions"
import { Modal } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { ModalProgressBar } from "../../../../../../components/partials/controls"


export const DciDeactivateDialog = ({ params, show, onHide }) => {
    const dcisUIContext = useDCIUIContext()
    const dcisUIProps = useMemo(() => {
      return {
        setIds: dcisUIContext.setIds,
        queryParams: dcisUIContext.queryParams,
      }
    }, [dcisUIContext])


  const dispatch = useDispatch()
  const { isLoading, success } = useSelector(
    (state) => ({ isLoading: state.admin.dci.isLoading, success: state.admin.dci.success }),
    shallowEqual
  )

  useEffect(() => {
    if (success.isDeactivated && show) {
      onHide()
      dispatch(fetchDcis(dcisUIProps.queryParams))
      dcisUIProps.setIds([])
    }
  }, [success, show, dcisUIProps])

  const ondeactivateDci = () => {
    dispatch(deactivateDci(params))
  }

    return (
        <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar variant="query" />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <FormattedMessage id="DCI.DEACTIVATE.TITLE" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="DCI.DEACTIVATE.MSG" />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-sm btn-light btn-elevate"
            >
              <FormattedMessage id="GENERAL.CANCEL" />
            </button>
            <> </>
            <button
              type="button"
              disabled={isLoading}
              onClick={ondeactivateDci}
              className="btn btn-sm btn-danger btn-elevate"
            >
              {isLoading && <span className="px-5 spinner spinner-white"></span>}
              <FormattedMessage id="GENERAL.DEACTIVATE" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )


}

export default DciDeactivateDialog
