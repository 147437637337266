import { MODULES_PERMISSIONS } from "src/constants";
import { lazy } from "react";
import { CREATE,VIEW,UPDATE,ACTIVATE } from "src/constants";
import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"



const TrainingsPage = lazy(() => import("../TrainingPage"));
const trainingdisplay= lazy(()=> import("../TrainingShow"))
const TrainigNewEdit= lazy(()=> import("../TrainigNewEdit"))


const { LIST_REFERENTIEL } = MODULES_PERMISSIONS;
export const TrainingPage = {
  path: "/trainings",
  component: TrainingsPage,
  can: LIST_REFERENTIEL.permissions[VIEW],
};
export const TrainingsEdit = {
  path: "/training/:param/edit",
  component: TrainigNewEdit
};
export const TrainingShow = {
  path: "/training/:param/show",
  component:trainingdisplay ,
  can: LIST_REFERENTIEL.permissions[VIEW]
}


// export const TrainerCreate = {
//   path: "/trainer/new",
//   component: Trainernew,
//   can: LIST_REFERENTIEL.permissions[VIEW],
// };

const path = routes.admin.path

export default combinePathRoutes({ path }, {
  TrainingPage,
  TrainingShow,
  TrainingsEdit
  
})