/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react";
import { FormattedMessage } from "react-intl";
import { Card, CardBody, CardHeader } from "..";
import { ControlUIProvider } from "../store";
import { CircularProgress } from "@mui/material";

const ShowCardView = ({ title, avatar, data, isLoading, className = "" }) => {
  return (
    <ControlUIProvider>
      <Card className={className}>
        <CardHeader
          className="d-flex align-items-center justify-content-center"
          title={title}
        ></CardHeader>
        <CardBody className="pt-0">
          <div
            style={{
              fontWeight: "bold",
              color: "#E02D39",
              fontSize: "5.5rem",
              lineHeight: "6.5rem",
            }}
            className="d-flex align-items-center justify-content-center w-100 h-100"
          >
            {isLoading ? (
              <CircularProgress
                size={88}
                thickness={5}
                disableShrink
                color="error"
              />
            ) : (
              data
            )}
          </div>
        </CardBody>
      </Card>
    </ControlUIProvider>
  );
};

export default ShowCardView;
