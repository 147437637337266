
export const FETCH_SESSIONS_INIT = "list@FETCH_SESSIONS_INIT";
export const FETCH_SESSIONS_SUCCEDED = "list@FETCH_SESSIONS_SUCCEDED";
export const FETCH_SESSIONS_FAILED = "list@FETCH_SESSIONS_FAILED";


export const FETCH_SESSION_INIT = "list@FETCH_SESSION_INIT";
export const FETCH_SESSION_SUCCEDED = "list@FETCH_SESSION_SUCCEDED";
export const FETCH_SESSION_FAILED = "list@FETCH_SESSION_FAILED";


