import React, { useRef } from 'react'
import _ from 'lodash'
import { Button, Col, Row } from 'react-bootstrap'
import { useFormikContext, FieldArray } from 'formik'

import { FastField } from "formik"
import { FORM_COMPONENT, DEFAULT_TYPE } from "./../types/inputTypes"

const FormRepeater = (props) => {
  // const addRef = useRef()
  const { children, label, min = 0, name, fields, showDeleteButton=true, showAddButton=true, max = 50, show = true, parentName = "" } = props

  const formik = useFormikContext()

  const fieldArrayName = React.useMemo(() => {
    return parentName + name 
    /*if (!_.isEmpty(nestedFields)){
      const field = nestedFields[0] || ''
      return field.name.substring(0, field.name.lastIndexOf('[]'))
    }
    return ''*/

    // eslint-disable-next-line
  }, [name])

  const arrayFieldValues = React.useMemo(() => {
    return _.get(formik.values, fieldArrayName, [])

    // eslint-disable-next-line
  }, [formik.values, fieldArrayName])

  const initialSnapshot = React.useMemo(() => {
    const snapshot = {}
    fields.forEach(field => {
      _.set(snapshot, field.name.substring(field.name.lastIndexOf('[]') + 1), _.get(field, 'initialValue', ''))
    })
    return snapshot

    // eslint-disable-next-line
  }, [fields])

  const renderField = ({ name, arrayName, component, ...props }) => (
    <FastField
      key={arrayName + name}
      name={ arrayName + name }
      component={FORM_COMPONENT[component] || DEFAULT_TYPE }
      { ...props }
    />
  )

  const renderFields = (arrayName, fields) => (<>
    {fields.map((input, i) => {
      const { validation, ...field } = input
      return (
          renderField({
            ...field,
            arrayName
          }) 
      )
    })}
  </>)

  return (
    <div className={!show ? 'd-none' : 'pb-3'}>
      <FieldArray
        name={fieldArrayName}
        render={arrayHelpers => {
          return (
            <div>
              { showAddButton && <Button
                disabled={_.isArray(arrayFieldValues) && arrayFieldValues.length >= max}
                // ref={addRef}
                className='btn btn-sm font-weight-bold btn-primary'
                onClick={() => _.isArray(arrayFieldValues) && arrayFieldValues.length < max && arrayHelpers.push(initialSnapshot)}
              >
                <i className='fas fa-plus' />
                {label}
              </Button>}
              {( _.has(formik.values, fieldArrayName) && _.isArray(arrayFieldValues)) && arrayFieldValues.map((__, index) => (
                <div className='mt-5 border-bottom' key={index}>
                  <Row>
                    <Col lg={ showDeleteButton ? 11 : 12}>
                      <Row className='d-flex align-items-end'>
                        { renderFields(`${fieldArrayName}.${index}.`, fields) }
                      </Row>
                      <Row>
                        <Col>
                      { !_.isEmpty(children) && React.cloneElement(children, { parentName: `${fieldArrayName}.${index}.` }) }
                        </Col></Row>
                    </Col>
                    {showDeleteButton && <Col lg='1' className='d-flex align-items-center justify-content-center'>
                      <Button
                        disabled={min > 0 ? _.isArray(arrayFieldValues) && arrayFieldValues.length === min : false}
                        className='btn btn-sm font-weight-bold btn-danger btn-icon'
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <i className='fas fa-trash-alt' />
                      </Button>
                    </Col> }
                  </Row>
                </div>
              ))}
            </div>
          )
        }}
      />
    </div>
  )
}
export default FormRepeater
