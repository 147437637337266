import React from 'react'
import { ShowModal } from 'src/components/partials'
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
const ShowmodalVd = ({intl}) => {
    const location=useLocation()
    const data =location.file
  return (
    <div>
         <ShowModal
    //   title={!_.isEmpty(params)
    //     ? intl.formatMessage({ id: "APTITUDE_SKILL.EDIT.TITLE" })
    //     : intl.formatMessage({ id: "APTITUDE_SKILL.NEW.TITLE" })}
    //   show={show}
    //   isFetching={isFetching}
    //   onHide={onHide}
    >{data}
      {/* <AptitudeSkill error={error} isFetching={isFetching} aptitudeSkill={aptitudeSkillForShow} /> */}
    </ShowModal>
    </div>
  )
}

export default ShowmodalVd