import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useFormikContext } from "formik";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import useHideAndShowField from "./hooks/useHideAndShowField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
export const RE_DIGIT = new RegExp(/^\d+$/);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiToggleButton-sizeSmall": {
      height: "1.25rem",
      width: "3.5652rem",
      background: "#F6F6F6",
      borderRadius: "0.25rem",
      color: "#979797",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
    },
    "& .Mui-selected": {
      background: "#DC3545 !important",
      color: "#F2F2F2 !important",
    },
  },
}));

const DatePickerField = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  inputGroupClassName = "form-group",
  inputClassName = "custom-select d-block w-100",
  format = "HH:mm:XM",
  size = 12,
  required = false,
  disabled= false,
  ...props
}) => {
  const classes = useStyles();

  const [view, setView] = React.useState("AM");

  const handleChange = (event, nextView) => {
    setView(nextView);
    if (state.minute && state.hour) {
      form.setFieldValue(
        field.name,
        format
          .replace("HH", state.hour)
          .replace("mm", state.minute)
          .replace("XM", nextView)
      );
    }
  };

  const { touched, errors } = form;

  const [state, setState] = useState({ hour: "", minute: "" });

  const fieldCSSClasses = useFieldCSSClasses(touched, errors, field.name);

  const formik = useFormikContext();
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });

  useEffect(() => {
    if (
      _.isString(field.value) &&
      field.value.split(":").every((val) => !_.isEmpty(val))
    ) {
      const res = field.value.split(":");
      setState({ minute: res[1], hour: res[0] });
      setView(res[2]);
    }
    // eslint-disable-next-line
  }, [field.value, field.name]);

 

  const inputHourOnChange = (e) => {
    setState({ ...state, hour: e.target.value.replace(/[^0-9]/g, "") });
    if (e.target.value.length === 2) {
      if (Number(e.target.value) > 12) setState({ ...state, hour: "" });
      else {
        if (state.minute && e.target.value) {
          form.setFieldValue(
            field.name,
            format
              .replace("HH", e.target.value)
              .replace("mm", state.minute)
              .replace("XM", view)
          );
        }
      }
    }
  };

  const inputMinutsOnChange = (e) => {
    setState({ ...state, minute: e.target.value.replace(/[^0-9]/g, "") });
    if (e.target.value.length === 2) {
      if (Number(e.target.value) > 59) setState({ ...state, minute: "" });
      else {
        if (state.hour && e.target.value) {
          form.setFieldValue(
            field.name,
            format
              .replace("HH", state.hour)
              .replace("mm", e.target.value)
              .replace("XM", view)
          );
        }
      }
    }
  };

  const otpStyle = {
    width: "4rem",
    height: "2.4rem",
    border: ".0625rem solid #D6D6D6",
    background: "#F6F6F6",
    textAlign: "center",
    borderRadius: ".25rem",
  };

  //defaultValue={(currentDate && currentDate.isValid()) ? currentDate.toDate() : undefined}
  return (
    <div
    dir="ltr"
      className={
        inputGroupClassName +
        conditionalShowAndHideClassName +
        ` col-lg-${size}`
      }
    >
      {label && (
        <label className="custom-label-style">
          {" "}
          {label} <span>{required && "*"}</span>
        </label>
      )}
      {/*value={moment(value, format)}*/}
      <div className="d-flex align-items-start gaps-6">
        <div className="d-flex flex-column ">
          <input
            id="hour"
            onChange={(e) => inputHourOnChange(e)}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{2}"
            maxLength={"2"}
            style={otpStyle}
            value={state.hour}
            disabled={disabled}
            {...props}
            className={` ${fieldCSSClasses}  ${disabled && `disabled`}`}
          />

          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#737373",
            }}
          >
            <FormattedMessage id="GENERAL.HOURS"/>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center fontSize-24 font-weight-bold">
          :
        </div>
        <div className="d-flex flex-column">
          <input
            onChange={(e) => inputMinutsOnChange(e)}
            type="text"
            id="minute"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{2}"
            maxLength={"2"}
            style={otpStyle}
            value={state.minute}
            className={` ${fieldCSSClasses} ${disabled && `disabled`}`}
            disabled={disabled}
            {...props}
          />
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#737373",
            }}
          >
                        <FormattedMessage id="GENERAL.MINUTE"/>

          </div>
        </div>

       
      </div>

      <FieldError fieldName={field.name} />
    </div>
  );
};

export default DatePickerField;
