// list
export const CLEAR_STUDENT = "student@CLEAR_STUDENT";

export const FETCH_STUDENTS_INIT = "list@FETCH_STUDENTS_INIT";
export const FETCH_STUDENTS_SUCCEDED = "list@FETCH_STUDENTS_SUCCEDED";
export const FETCH_STUDENTS_FAILED = "list@FETCH_STUDENTS_FAILED";

export const CREATE_STUDENT_INIT = "STUDENT@CREATE_STUDENT_INIT";
export const CREATE_STUDENT_SUCCEDED = "STUDENT@CREATE_STUDENT_SUCCEDED";
export const CREATE_STUDENT_FAILED = "STUDENT@CREATE_STUDENT_FAILED";

export const FETCH_STUDENT_INIT = "STUDENT@FETCH_STUDENT_INIT";
export const FETCH_STUDENT_SUCCEDED = "STUDENT@FETCH_STUDENT_SUCCEDED";
export const FETCH_STUDENT_FAILED = "STUDENT@FETCH_STUDENT_FAILED";


export const EDIT_STUDENT_INIT = "STUDENT@EDIT_STUDENT_INIT";
export const EDIT_STUDENT_SUCCEDED = "STUDENT@EDIT_STUDENT_SUCCEDED";
export const EDIT_STUDENT_FAILED = "STUDENT@EDIT_STUDENT_FAILED";

export const DELETE_STUDENT_INIT = "STUDENT@DELETE_STUDENT_INIT";
export const DELETE_STUDENT_SUCCEDED = "STUDENT@DELETE_STUDENT_SUCCEDED";
export const DELETE_STUDENT_FAILED = "STUDENT@DELETE_STUDENT_FAILED";


export const FETCH_FETCH_STUDENT_EXTRA_DATA_INIT = "STUDENT@FETCH_STUDENT_INIT";
export const FETCH_STUDENT_EXTRA_DATA_SUCCEDED = "STUDENT@FETCH_STUDENT_SUCCEDED";
export const FETCH_STUDENT_EXTRA_DATA_FAILED = "STUDENT@FETCH_STUDENT_FAILED";


