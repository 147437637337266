import { ACTIONS, ENDPOINTS } from "../constants"
import { CALL_API, HTTP_METHODS } from "../../../../../../constants"


export const clearStore = () =>
({
  type: ACTIONS.CLEAR_FAQS
})


export const fetchFAQs = (params) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_FAQS_INIT,
      success: ACTIONS.FETCH_FAQS_SUCCEDED,
      fail: ACTIONS.FETCH_FAQS_FAILED
    },
    endpoint: ENDPOINTS.FAQSS,
    method: HTTP_METHODS.GET,
    params,
    auth: true
  }
})

export const createFAQ = (payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_FAQS_INIT,
      success: ACTIONS.CREATE_FAQS_SUCCEDED,
      fail: ACTIONS.FETCH_FAQS_FAILED
    },
    endpoint: ENDPOINTS.FAQS_CREATE,
    method: HTTP_METHODS.POST,
    auth: true
  }
})

export const deleteFaq = ({ param }, payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.DELETE_FAQS_INIT,
      success: ACTIONS.DELETE_FAQS_SUCCEDED,
      fail: ACTIONS.DELETE_FAQS_FAILED
    },
    endpoint: ENDPOINTS.DELETEFAQ.replace(":param", param),
    method: HTTP_METHODS.DELETE,
    auth: true
  }
})

export const EditFaq = ( param , payload) =>
  ({
    type: CALL_API,
    payload,
    meta: {
      actions: {
        init: ACTIONS.EDIT_FAQS_INIT,
        success: ACTIONS.EDIT_FAQS_SUCCEDED,
        fail: ACTIONS.EDIT_FAQS_FAILED
      },
      endpoint: ENDPOINTS.FAQS_UPDATE.replace(":param", param),
      method: HTTP_METHODS.PATCH,
      auth: true
    }
  })
  export const fetchExtra_Data_FAQs = ( param ) => ({
    type: CALL_API,
    
    meta: {
      actions: {
        init: ACTIONS.FETCH_FAQ_INIT,
        success: ACTIONS.FETCH_FAQ_SUCCEDED,
        fail: ACTIONS.FETCH_FAQ_FAILED
      },
      //isFormData: true,
      endpoint: ENDPOINTS.FAQ.replace(":param", param),
      method: HTTP_METHODS.GET,
      auth: true,
    },
  });