/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLang, setLanguage, isRLTLang } from "../../../i18n";
import { DropdownTopbarItemToggler } from "./../../partials/dropdowns";
import { FR, AR} from "../../../constants";
import { toAbsoluteUrl } from "../../../helpers";
import SVG from "react-inlinesvg"

const languages = [
  // {
  //   lang: EN,
  //   name: "English",
  //   code: "EN",
  //   flag: toAbsoluteUrl("/media/svg/flags/012-uk.svg"),
  // },
  {
    lang: AR,
    name: "عربي",
    code: 'AR',
    flag: toAbsoluteUrl("/media/svg/flags/049-tunisia.svg"),
  },
  {
    lang: FR,
    name: "Français",
    code: "FR",
    flag: toAbsoluteUrl("/media/svg/flags/195-france.svg"),
  },
];

const LanguageSelectorDropdown = () => {
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);
  const availableLanguage = languages.find((x) => x.lang === lang);
  return (
    <Dropdown drop="down" alignRight>
      < Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-my-cart"
        className="mb-2"
      >
        {/* <OverlayTrigger
          placement={!isRLTLang ? "right" : "left"}
          overlay={
            <Tooltip id="language-panel-tooltip">
              {" "}
              <FormattedMessage id="GENERAL.SELECT_LANGUAGE" />{" "}
            </Tooltip>
          }
        > */}
         <div dir={`${!isRLTLang() ? "rtl":"ltr"}`}style={{gap:'16px'}} className="d-flex align-items-center btn">
           <div style={{gap:'6px'}} className="d-flex align-items-center">
           {/* <img
              className="w-34px h-24px w-lg-34px h-lg-24px"
              width="34"
              height="24"
              style={{objectFit:'cover'}}
              src={availableLanguage.flag}
              alt={availableLanguage.name}
            /> */}
          <div style={{
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '28px',
          }}>{availableLanguage.code}</div>
           </div>
           <SVG
                        src={toAbsoluteUrl("/media/svg/dropArrow.svg")}
                    ></SVG>
         </div>
        {/* </OverlayTrigger> */}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={
          "dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-" +
          (!isRLTLang() ? "left" : "right")
        }
      >
        <ul className="navi navi-hover py-4">
          {languages.map((language) => (
            <li
              key={language.lang}
              className={clsx("navi-item", {
                active: language.lang === currentLanguage.lang,
              })}
            >
              <a
                href="#"
                onClick={() => setLanguage(language.lang)}
                className="navi-link"
              >
                <span className={"navi-text text-" + (!isRLTLang() ? "left" : "right") }>{language.name}</span>
                <span className="symbol symbol-20">
                  {/* <img
                    width="25"
                    height="25"
                    src={language.flag}
                    alt={language.name}
                  /> */}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelectorDropdown;
