export const FETCH_SPECIALTIES_INIT = "Specialtie@FETCH_SPECIALTIES_INIT"
export const FETCH_SPECIALTIES_SUCCEDED = "Specialtie@FETCH_SPECIALTIES_SUCCEDED"
export const FETCH_SPECIALTIES_FAILED = "Specialtie@FETCH_SPECIALTIES_FAILED"


export const FETCH_SPECIALTIESS_INIT = "Specialtie@FETCH_SPECIALTIESS_INIT"
export const FETCH_SPECIALTIESS_SUCCEDED = "Specialtie@FETCH_SPECIALTIESS_SUCCEDED"
export const FETCH_SPECIALTIESS_FAILED = "Specialtie@FETCH_SPECIALTIESS_FAILED"

export const CLEAR_SPECIALTIES = "Specialtie@CLEAR_SPECIALTIES"

export const CREATE_SPECIALTIES_INIT = "Specialtie@CREATE_SPECIALTIES_INIT"
export const CREATE_SPECIALTIES_SUCCEDED = "Specialtie@CREATE_SPECIALTIES_SUCCEDED"
export const CREATE_SPECIALTIES_FAILED = "Specialtie@CREATE_SPECIALTIES_FAILED"


export const EDIT_SPECIALTIES_INIT = "Specialtie@EDIT_SPECIALTIES_INIT"
export const EDIT_SPECIALTIES_SUCCEDED = "Specialtie@EDIT_SPECIALTIES_SUCCEDED"
export const EDIT_SPECIALTIES_FAILED = "Specialtie@EDIT_SPECIALTIES_FAILED"

export const DELETE_SPECIALTIES_INIT = "Specialtie@DELETE_SPECIALTIES_INIT"
export const DELETE_SPECIALTIES_SUCCEDED = "Specialtie@DELETE_SPECIALTIES_SUCCEDED"
export const DELETE_SPECIALTIES_FAILED = "Specialtie@DELETE_SPECIALTIES_FAILED"


export const DEACTIVATE_SPECIALTIES_INIT = "Specialtie@DEACTIVATE_SPECIALTIES_INIT"
export const DEACTIVATE_SPECIALTIES_SUCCEDED = "Specialtie@DEACTIVATE_SPECIALTIES_SUCCEDED"
export const DEACTIVATE_SPECIALTIES_FAILED = "Specialtie@DEACTIVATE_SPECIALTIES_FAILED"

export const ACTIVATE_SPECIALTIES_INIT = "Specialtie@ACTIVATE_SPECIALTIES_INIT"
export const ACTIVATE_SPECIALTIES_SUCCEDED = "Specialtie@ACTIVATE_SPECIALTIES_SUCCEDED"
export const ACTIVATE_SPECIALTIES_FAILED = "Specialtie@ACTIVATE_SPECIALTIES_FAILED"
