import { ACTIONS } from "../constants"


const initialState = {
  products: [],
  product: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
    isUploaded: false,
  },
  error: null
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_STORE: {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.CREATE_PRODUCT_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_PRODUCT_SUCCEDED: {
      console.log(payload)
      console.log(state.products)
      return { ...state,
         success: { ...state.success, isCreated: true },
          products:[...state.products,payload],
          isLoading: false,
           error: null }
    }
    case ACTIONS.CREATE_PRODUCT_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }


    case ACTIONS.UPLOAD_FILE_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.UPLOAD_FILE_SUCCEDED: {
    
      return { ...state, url:payload,success: { ...state.success, isUploaded: true }, isLoading: false, error: null }
    }
    case ACTIONS.UPLOAD_FILE_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }
    case ACTIONS.UPLOAD_VIDEO_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.UPLOAD_VIDEO_SUCCEDED: {
    
      return { ...state, product: {
        ...state.product,
        videos: [...(state.product.videos || []), payload]  
      },success: { ...state.success, isUploaded: true }, isLoading: false, error: null }
    }
    case ACTIONS.UPLOAD_VIDEO_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }
    case ACTIONS.UPLOAD_DOCUMENT_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.UPLOAD_DOCUMENT_SUCCEDED: {
    
      return { ...state, product: {
        ...state.product,
        documents: [...(state.product.documents || []), payload] 
      },success: { ...state.success, isUploaded: true }, isLoading: false, error: null }
    }
    case ACTIONS.UPLOAD_DOCUMENT_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.FETCH_PRODUCTS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_PRODUCTS_SUCCEDED: {
      const { count, results } = payload
      return { ...state, totalSize: count, products: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_PRODUCTS_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }
    case ACTIONS.FETCH_PRODUCT_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_PRODUCT_SUCCEDED: {
      
      return { ...state, product: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_PRODUCT_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

   

    
    




    


   

    case ACTIONS.DELETE_VIDEO_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_VIDEO_SUCCEDED: {
      const { id } = payload;
      
      return { 
        ...state, 
        success: { 
          ...state.success, 
          isDeleted: true 
        }, 
        product: {
          ...state.product,
          videos: state.product.videos.filter(video => video.id !== id)  
        },
        isLoading: false, 
        error: null 
      };
    }
    case ACTIONS.DELETE_VIDEO_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }
    default: {
      return state
    }
  }
}
