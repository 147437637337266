import React, { createContext, useContext, useState, useCallback } from "react"
import { isEqual, isFunction } from "lodash"
import { IS_ACTIVE, IS_ACTIVE_INIT_VALUE } from "./../store/constants"

const SpecialtiesUIContext = createContext()

export function useSpecialtiesUIContext() {
    return useContext(SpecialtiesUIContext)
}

export const SpecialtiesUIConsumer = () => SpecialtiesUIContext.Consumer

export function SpecialtiesUIProvider({ specialtiesUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState({ [IS_ACTIVE]: IS_ACTIVE_INIT_VALUE })
    const [ids, setIds] = useState([])
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }

            return nextQueryParams
        })
    }, [])

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        ...specialtiesUIEvents
    }

    return (
        <SpecialtiesUIContext.Provider value={value}>
            {children}
        </SpecialtiesUIContext.Provider>
    )
}
