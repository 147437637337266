import { ACTIONS } from "../constants"


const initialState = {
  faqs: [],
  faq: {},
  totalSize: 0,
  isFetching: false,
  isLoading: false,
  hasMore: true,
  success: {
    isDeactivated: false,
    isActivated: false,
    isCreated: false,
    isUpdated: false,
    isDeleted: false,
  },
  error: null
}


export default (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {

    case ACTIONS.CLEAR_FAQS: {
      return { ...state, success: initialState.success, error: null, isFetching: false, isLoading: false }
    }

    case ACTIONS.FETCH_FAQS_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_FAQS_SUCCEDED: {
      const { count, results } = payload
      return { ...state, totalSize: count, faqs: results, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_FAQS_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }
    case ACTIONS.FETCH_FAQ_INIT: {
      return { ...state, isFetching: true, error: null }
    }
    case ACTIONS.FETCH_FAQ_SUCCEDED: {
      const { count, results } = payload
      return { ...state, totalSize: count, faq: payload, isFetching: false, error: null }
    }
    case ACTIONS.FETCH_FAQ_FAILED: {
      return { ...state, isFetching: false, error: payload }
    }

    case ACTIONS.CREATE_FAQS_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.CREATE_FAQS_SUCCEDED: {
     
      return { ...state,
         success: { ...state.success, isCreated: true },
          isLoading: false,
          faqs:[...state.faqs,payload],
           error: null 
          }
    }
    case ACTIONS.CREATE_FAQS_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    case ACTIONS.EDIT_FAQS_INIT: {
      return { ...state, isLoading: true, error: null, success: initialState.success }
    }
    case ACTIONS.EDIT_FAQS_SUCCEDED: {
      console.log({ payload }, "dali");
    
      return {
        ...state,
        success: { ...state.success, isUpdated: true },
        faqs: state.faqs.map(faq =>
          faq.id === payload.id ? { ...faq, ...payload } : faq
        ),
        isLoading: false,
        error: null,
      };
    }
    case ACTIONS.EDIT_FAQS_FAILED: {
      return { ...state, error: payload, isLoading: false }
    }

    
    




    case ACTIONS.DEACTIVATE_FAQS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DEACTIVATE_FAQS_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeactivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.DEACTIVATE_FAQS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }



    case ACTIONS.ACTIVATE_FAQS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.ACTIVATE_FAQS_SUCCEDED: {
      return { ...state, success: { ...state.success, isActivated: true }, isLoading: false, error: null }
    }
    case ACTIONS.ACTIVATE_FAQS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }



    case ACTIONS.DELETE_FAQS_INIT: {
      return { ...state, isLoading: true, success: initialState.success, error: null }
    }
    case ACTIONS.DELETE_FAQS_SUCCEDED: {
      return { ...state, success: { ...state.success, isDeleted: true }, isLoading: false, error: null }
    }
    case ACTIONS.DELETE_FAQS_FAILED: {
      return { ...state, isLoading: false, error: payload }
    }
    default: {
      return state
    }
  }
}
