export const TRAININGS = "/api/gettrainings";
// export const ADD_TRAINING="/api/newtrainer";
export const TRAINING = "/api/training/:param";
// export const TRAININGS_EDIT = "/api/trainer/:param/edit";
export const STUDENTS = "/api/getstudents";
export const MATCHSTUDENTS = "/api/matchlist/:param";
export const ASIGN = "/api/addmatch";
export const SESSION_TRAINING = "/api/sessionsTraining/:param";

export const PROGRAM = "/api/fetchtrainings/:param";
export const EDIT_PROGRAM = "/api/updatetraining/:param"