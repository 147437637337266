import { DEACTIVATE, ACTIVATE } from "../../../../../constants"

import { VaccinationManagement } from "src/constants/ModulesPermissions"
import routes from "src/routes"
import { lazy } from "react"
import { combinePathRoutes } from "src/helpers"
import { SpecialtieActivateDialog } from "../components/dialog/SpecialtieCardActivateDialog"
import { SpecialtieDeactivateDialog } from "../components/dialog/SpecialtieCardDeactivateDialog"
import { MODULES_PERMISSIONS } from "src/constants";
import { pages } from "./index"

const { SPECIALTIE } = MODULES_PERMISSIONS;
export const specialtieActivate = {
  path: "/activate/:param",
  component: SpecialtieActivateDialog,
  can: SPECIALTIE.permissions[ACTIVATE]
}

export const specialtieDeactivate = {
  path: "/deactivate/:param",
  component: SpecialtieDeactivateDialog,
  can: SPECIALTIE.permissions[DEACTIVATE]
}

const path = pages.specialtieList.path

export default combinePathRoutes({ path }, {
  specialtieActivate,
  specialtieDeactivate
})
