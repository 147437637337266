import { TABLE_OF_ITEMS } from "../../../../../../../components/partials";



// Validation schema
export const listFieldsAr = ({ intl }) => [
    {
        name: "nameAr",
        label: intl.formatMessage({ id: "LIST.MENU.EDIT.TITLE" }),
        size: 8,
    },
    {
        name: "optionSet",
        showActions: false,
        showSearchField:false,
        columns: [
            {
                dataField: "rank",
                text: intl.formatMessage({
                    id: "LIST.INPUT.ORDRE",
                }),
                size: 2,
            },
            {
                dataField: "valueAr",
                text: intl.formatMessage({
                    id: "LIST.INPUT.OPTION",
                }),
                size: 10,
            },
        ],
        component: TABLE_OF_ITEMS,
        
        size: 12,
    },
];


export const listFieldsFr = ({ intl }) => [
    {
        name: "nameFr",
        label: intl.formatMessage({ id: "LIST.MENU.EDIT.TITLE" }),
        size: 8,
    },
    {
        name: "optionSet",
        showActions: false,
        showSearchField:false,
        columns: [
            {
                dataField: "rank",
                text: intl.formatMessage({
                    id: "LIST.INPUT.ORDRE",
                }),
                size: 2,
            },
            {
                dataField: "valueFr",
                text: intl.formatMessage({
                    id: "LIST.INPUT.OPTION",
                }),
                size: 10,
            },
        ],
        component: TABLE_OF_ITEMS,
        size: 12,
    },
];
