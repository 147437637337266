/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React from "react"
import { FormattedMessage } from "react-intl"
import { Card, CardBody, CardHeader, CardHeaderToolbar, ModalProgressBar, CardFooter } from "../../controls"
import _ from "lodash"

import { isRLTLang } from "./../../../../i18n"

import {Button} from "react-bootstrap"
import DownloadItems from "../download-pdf/DownloadItems"
import {ControlUIProvider} from "../store"


const ShowViewReferentiel = ({ children, title, goBackTo, goToEdit, print = true, printURL, toolBar, isFetching }) => {

  return (
    <ControlUIProvider>
      <Card>
        {isFetching && <ModalProgressBar />}
        <CardHeader title={title}>
          {/* <CardHeaderToolbar>
            { goBackTo && <Button
              type="button"
              onClick={goBackTo}
              className="btn btn-sm btn-light mx-2"
            >
              { isRLTLang() ?
                <>
                  <FormattedMessage id="GENERAL.BACK" />
                  <i className="fa fa-arrow-left" />
                </>
                : <>
                  <i className="fa fa-arrow-left" />
                  <FormattedMessage id="GENERAL.BACK" />
                </>
              }
            </Button>
            }
            { _.isFunction(goToEdit) && <Button
              type="button"
              className="btn btn-sm btn-primary spinner-white mx-3 my-1 "
              onClick={goToEdit}
            >
              <FormattedMessage id="GENERAL.EDIT" />
            </Button> }
            { print && <DownloadItems title={title} printURL={printURL} /> }
            { toolBar }
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody>
          { children }
        </CardBody>
              <CardFooter className="d-flex justify-content-between">
              { goBackTo && <Button
              type="button"
              onClick={goBackTo}
              className="btn btn-sm btn-primary mx-2"
            >
              { isRLTLang() ?
                <>
                  <FormattedMessage id="GENERAL.BACK" />
                  <i className="fa fa-arrow-left" />
                </>
                : <>
                  <i className="fa fa-arrow-left" />
                  <FormattedMessage id="GENERAL.BACK" />
                </>
              }
            </Button>
            }
            { _.isFunction(goToEdit) && <Button
              type="button"
              className="btn btn-sm btn-primary spinner-white mx-3 my-1 "
              onClick={goToEdit}
            >
              <FormattedMessage id="GENERAL.EDIT" />
            </Button> }
            { print && <DownloadItems title={title} printURL={printURL} /> }
            { toolBar }
      </CardFooter>
      </Card>
    </ControlUIProvider>
  )
}


export default ShowViewReferentiel
