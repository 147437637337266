export const FETCH_PRODUCTS_INIT = "FAQ@FETCH_PRODUCT_INIT"
export const FETCH_PRODUCTS_SUCCEDED = "FAQ@FETCH_PRODUCT_SUCCEDED"
export const FETCH_PRODUCTS_FAILED = "FAQ@FETCH_PRODUCT_FAILED"


export const CREATE_PRODUCT_INIT = "FAQ@CREATE_PRODUCT_INIT"
export const CREATE_PRODUCT_SUCCEDED = "FAQ@CREATE_PRODUCT_SUCCEDED"
export const CREATE_PRODUCT_FAILED = "FAQ@CREATE_PRODUCT_FAILED"

export const UPLOAD_DOCUMENT_INIT = "FAQ@UPLOAD_DOCUMENT_INIT"
export const UPLOAD_DOCUMENT_SUCCEDED = "FAQ@UPLOAD_DOCUMENT_SUCCEDED"
export const UPLOAD_DOCUMENT_FAILED = "FAQ@UPLOAD_DOCUMENT_FAILED"

export const DELETE_VIDEO_INIT = "FAQ@DELETE_VIDEO_INIT"
export const DELETE_VIDEO_SUCCEDED = "FAQ@DELETE_VIDEO_SUCCEDED"
export const DELETE_VIDEO_FAILED = "FAQ@DELETE_VIDEO_FAILED"

export const UPLOAD_VIDEO_INIT = "FAQ@UPLOAD_VIDEO_INIT"
export const UPLOAD_VIDEO_SUCCEDED = "FAQ@UPLOAD_VIDEO_SUCCEDED"
export const UPLOAD_VIDEO_FAILED = "FAQ@UPLOAD_VIDEO_FAILED"

export const UPLOAD_FILE_INIT = "FAQ@UPLOAD_FILE_INIT"
export const UPLOAD_FILE_SUCCEDED = "FAQ@UPLOAD_FILE_SUCCEDED"
export const UPLOAD_FILE_FAILED = "FAQ@UPLOAD_FILE_FAILED"

export const CLEAR_PRODUCT = "FAQ@CLEAR_PRODUCT"

export const FETCH_PRODUCT_INIT = "PRODUCT@FETCH_PRODUCT_INIT"
export const FETCH_PRODUCT_SUCCEDED = "PRODUCT@FETCH_PRODUCT_SUCCEDED"
export const FETCH_PRODUCT_FAILED = "PRODUCT@FETCH_PRODUCT_FAILED"



export const EDIT_PRODUCT_INIT = "FAQ@EDIT_PRODUCT_INIT"
export const EDIT_PRODUCT_SUCCEDED = "FAQ@EDIT_PRODUCT_SUCCEDED"
export const EDIT_PRODUCT_FAILED = "FAQ@EDIT_PRODUCT_FAILED"

export const DELETE_PRODUCT_INIT = "FAQ@DELETE_PRODUCT_INIT"
export const DELETE_PRODUCT_SUCCEDED = "FAQ@DELETE_PRODUCT_SUCCEDED"
export const DELETE_PRODUCT_FAILED = "FAQ@DELETE_PRODUCT_FAILED"


export const DEACTIVATE_PRODUCT_INIT = "FAQ@DEACTIVATE_PRODUCT_INIT"
export const DEACTIVATE_PRODUCT_SUCCEDED = "FAQ@DEACTIVATE_PRODUCT_SUCCEDED"
export const DEACTIVATE_PRODUCT_FAILED = "FAQ@DEACTIVATE_PRODUCT_FAILED"

export const ACTIVATE_PRODUCT_INIT = "FAQ@ACTIVATE_PRODUCT_INIT"
export const ACTIVATE_PRODUCT_SUCCEDED = "FAQ@ACTIVATE_PRODUCT_SUCCEDED"
export const ACTIVATE_PRODUCT_FAILED = "FAQ@ACTIVATE_PRODUCT_FAILED"
