import React, {useEffect, useState} from "react"
import _ from "lodash"
import { useFormikContext } from "formik"
import { FormattedMessage } from "react-intl"
import Dropzone from "react-dropzone-uploader"
import { FieldError, useFieldCSSClasses } from "./FieldError"
import Preview from "./dropzone/Preview"
import useHideAndShowField from "./hooks/useHideAndShowField"
import uuid from "uuid"
import {convertUrlToImagesData} from "src/helpers"
import withCropper from "./dropzone/PreviewHOC"

let key = null
const Uploader = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  maxFiles = 10,
  multiple = false,
  accept,
  formatter=(value) => value.substring(value.lastIndexOf('/')+1),
  required=false,
  autoUpload=true,
  ...props
}) => {

  const formik = useFormikContext()
  const [editable, setEditable] = useState(false)
  const fieldCSSClasses = useFieldCSSClasses(form.touched, form.errors, field.name)
  const conditionalShowAndHideClassName = useHideAndShowField({ formik, fieldName: field.name, ...props })

  const [initFiles, setInitFiles] = useState([])

  useEffect(() => {
    if (_.isArray(field.value) && !editable && !_.isEmpty(field.value) && field.value.every((val) => val instanceof File )){
      setEditable(true)
      setInitFiles([...field.value])
      key = uuid()
    }else if (_.isArray(field.value) && !_.isEmpty(field.value) && field.value.every((val) => _.isString(val))){
      convertUrlToImagesData(field.value)
        .then((files) => { 
          form.setFieldValue(field.name, files)
          setInitFiles(files) 
          setEditable(true)
          key = uuid()
        })
        .catch(() => { setInitFiles([]) })
    }
    else if (_.isArray(field.value) && !editable && !_.isEmpty(field.value) && field.value.some((val) => _.isString(val))){
      setEditable(true)
      convertUrlToImagesData(field.value.filter((val) => _.isString(val)))
        .then((files) => {
          form.setFieldValue(field.name, [ ...(field.value.filter((val) => val instanceof File)), ...files])
          setInitFiles([ ...(field.value.filter((val) => val instanceof File)), ...files])
          key = uuid()
        })
        .catch(() => { setInitFiles([]) })
    }

    // eslint-disable-next-line
  }, [field.value])

  useEffect(() => {
    if (_.isEmpty(field.value) && !editable){
      setEditable(false)
      key = uuid()
    }

    // eslint-disable-next-line
  }, [formik.submitCount])
  
  const handleChangeStatus = ({ file, meta }, status) => {
    if (status === "done" && meta.size > 0){
      setEditable(true)
      form.setFieldValue(field.name, _.uniqBy([...(field.value||[]), file], 'name'))
    }
    if (status === "done" && meta.size === 0){
      //form.setFieldValue(field.name, undefined)
    }
    if (status === "removed"){
      form.setFieldValue(field.name, _.uniqBy(field.value.filter((img) => meta.name !== (_.isString(img) ? formatter(img) : img.name)), 'name'))
    }
  }

  const onCropImg = (croppedBlob, file) => {
    form.setFieldValue(field.name, field.value.map((elem) => 
      file.name === elem.name
        ? new File([croppedBlob], file.name, { lastModified: new Date().getTime(), type: croppedBlob.type })
        : elem
    ))
  }

  return (
    <div className={`form-group ${fieldCSSClasses} ${conditionalShowAndHideClassName}` }>
      <label>{ label } {required && "*"}</label>
      <Dropzone
        key={key}
        onChangeStatus={handleChangeStatus}
        maxFiles={maxFiles}
        multiple={true}
        initialFiles={initFiles}
        PreviewComponent={withCropper(Preview, onCropImg)}
        inputContent={<FormattedMessage key={1} id="GENERAL.UPLOAD_FILE_CONTENT" />}
        inputWithFilesContent={<FormattedMessage key={1} id="GENERAL.UPLOAD_FILE_INPUT" />}
        autoUpload={autoUpload}
      />
      <FieldError fieldName={field.name} />
    </div>
  )
}


export default Uploader
