/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import axios from 'axios'
import { FormattedMessage } from "react-intl"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { FormModal, ModalProgressBar } from "../../../../../../components/partials/controls"
// import { deactivateService, fetchServices } from "../../store/actions"
import { fetchSpecialties, deactivateSpecialtie, createFAQ, fetchFAQs, createProduct, uploadFile } from "../../store/actions"
import { useFAQsUIContext } from "../../context/ProductsUIContext"
import DynamicForm from "src/components/partials/controls/forms/dynamic-form-without-store/DynamicForm"

import {productfieldes} from "./fields/productfieldes"
export const AjoutProductDialog = ({ params, show, onHide,intl }) => {
  // // Services UI Context
  // const FAQsUIContext = useFAQsUIContext()

  const fieldsFr = productfieldes({ intl })
  // // Services Redux state
  const dispatch = useDispatch()
  const { url,isLoading, success ,error=false} = useSelector(
    (state) => ({ isLoading: state.admin.faq.isLoading,
       success: state.admin.faq.success ,
       error:state.admin.faq.error,
       url:state.admin.Product.url,
      }),
    shallowEqual
  )

  
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [level, setLevel] = useState('beginner');
  const [language, setLanguage] = useState('english');
  const [publishedDate, setPublishedDate] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState();
  const [instructor, setInstructor] = useState('');
  const [category, setCategory] = useState('');
  const [documents, setDocuments] = useState([]);
  const [documentURL, setDocumentURL] = useState('');
  
  
 
  const handleSubmit=(e)=>{
    const data={title,description,price,duration,level,language,publishedDate,isActive,image}
    e.preventDefault()
    dispatch(createProduct(data))
    onHide()
   
  }
  
  const uploadImage=async(file)=>{

    if (file==null)return;
    const data= new FormData()
    data.append("image",file[0])
    console.log(data)
    const response2= await axios.post("https://admin-learning-api.infinitymgt.fr/api/uploadFileProduct",data) 
    setImage(response2.data.url)
    console.log(response2.data.url)

}


  return (
    
    <FormModal
      success={success}
      onHide={() => onHide()}
      show={show}
      size="md"
      subtitle={<FormattedMessage id="FAQ.TITLE_AJOUT" />}
    >
      {({ saveRef }) => (
          <form onSubmit={handleSubmit} className="container mt-5">
          <div className="form-group">
            <label>titre:</label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <textarea
              name="description"
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Prix:</label>
            <input
              type="number"
              name="price"
              className="form-control"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Duree (heure):</label>
            <input
              type="number"
              name="duration"
              className="form-control"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Level:</label>
            <select
              name="level"
              className="form-control"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
          </div>
          <div className="form-group">
            <label>Langue:</label>
            <select
              name="language"
              className="form-control"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="english">English</option>
              <option value="french">French</option>
              <option value="spanish">Spanish</option>
              <option value="german">German</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label>Date de publication:</label>
            <input
              type="date"
              name="published_date"
              className="form-control"
              value={publishedDate}
              onChange={(e) => setPublishedDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              name="is_active"
              className="form-check-input"
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
            <label className="form-check-label">Is Active</label>
          </div>
          <div className="input-group">
                <input type="text" className="form-control" name="image"
                       value={image}
                       
                />
                <div className="input-group-append">
                    <label className="btn btn-primary">
                        Upload <input type="file" hidden onChange={(e)=>uploadImage(e.target.files)}/>
                    </label>
                </div>
            </div>
         
          {/* <div className="form-group">
            <label>Document URL:</label>
            <input
              type="file"
              className="form-control-file"
              value={documentURL}
              onChange={(e) => setDocumentURL(e.target.value)}
            />
            <button type="button" className="btn btn-primary mt-2" onClick={handleAddDocument}>
              Add Document
            </button> */}
          {/* </div> */}
          {/* <div className="form-group">
            <label>Documents:</label>
            <ul className="list-group">
              {formData.documents.map((doc, index) => (
                <li key={index} className="list-group-item">
                  {doc}
                </li>
              ))}
            </ul>
          </div> */}
          <button type="submit" className="btn btn-success">Ajouter produit</button>
        </form>
      )}
    </FormModal>
  )
}


export default AjoutProductDialog 
