/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react"
import { Modal } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { ModalProgressBar } from "../../../../../../components/partials/controls"
// import { activateService, fetchServices } from "../../store/actions"
import { activateSpecialtie, fetchSpecialties } from "../../store/actions"
// import { useServicesUIContext } from "../../context/ServicesUIContext"
import { useSpecialtiesUIContext } from "../../context/SpecialtiesUIContext"

export const SpecialtieActivateDialog = ({ params, show, onHide }) => {
  // Services UI Context
  const specialtiesUIContext = useSpecialtiesUIContext()
  const specialtiesUIProps = useMemo(() => {
    return {
      setIds: specialtiesUIContext.setIds,
      queryParams: specialtiesUIContext.queryParams,
    }
  }, [specialtiesUIContext])

  // Services Redux state
  const dispatch = useDispatch()
  const { isLoading, success } = useSelector(
    (state) => ({ isLoading: state.admin.specialtie.isLoading, success: state.admin.specialtie.success }),
    shallowEqual
  )

  // if !id we should close modal
  useEffect(() => {
    if (success.isActivated && show) {
      onHide()
      dispatch(fetchSpecialties(specialtiesUIProps.queryParams))
      specialtiesUIProps.setIds([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, show, specialtiesUIProps])

  const onActivateSpecialtie = () => {
    // server request for deleting service by id
    dispatch(activateSpecialtie(params))
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <FormattedMessage id="SPECIALTIS.ACTIVATE.TITLE" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage id="SPECIALTIS.ACTIVATE.MSG" />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-sm btn-light btn-elevate"
          >
            <FormattedMessage id="GENERAL.CANCEL" />
          </button>
          <> </>
          <button
            type="button"
            disabled={isLoading}
            onClick={onActivateSpecialtie}
            className="btn btn-sm btn-success btn-elevate"
          >
            {isLoading && <span className="px-5 spinner spinner-white"></span>}
            <FormattedMessage id="GENERAL.ACTIVATE" />
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}


export default SpecialtieActivateDialog
