export const SPECIALTIESS = "/api/specialityList"
export const SPECIALTIE = "/api/specialityList?id=:param"
export const SPECIALTIES_CREATE = "/api/ADD_Specialtie"
// export const SPECIALTIES = "/api/specialityList"
export const SPECIALTIES_ACTIVATE = "/api/Specialtie/:param/activate"
export const SPECIALTIES_DEACTIVATE = "/api/Specialtie/:param/deactivate"
export const SPECIALTIES_DELETE = "/api/Specialtie/:param/delete"

export const EXPORT_SPECIALTIES = "/api/Specialtie/export"
export const IMPORT_SPECIALTIES = "/api/Specialtie/import"
