// list
export const CLEAR_USER = "USER@CLEAR_USER";

export const FETCH_USERS_INIT = "list@FETCH_USERS_INIT";
export const FETCH_USERS_SUCCEDED = "list@FETCH_USERS_SUCCEDED";
export const FETCH_USERS_FAILED = "list@FETCH_USERS_FAILED";

export const CREATE_USER_INIT = "USER@CREATE_USER_INIT";
export const CREATE_USER_SUCCEDED = "USER@CREATE_USER_SUCCEDED";
export const CREATE_USER_FAILED = "USER@CREATE_USER_FAILED";

export const FETCH_USER_INIT = "USER@FETCH_USER_INIT";
export const FETCH_USER_SUCCEDED = "USER@FETCH_USER_SUCCEDED";
export const FETCH_USER_FAILED = "USER@FETCH_USER_FAILED";


export const EDIT_USER_INIT = "USER@EDIT_USER_INIT";
export const EDIT_USER_SUCCEDED = "USER@EDIT_USER_SUCCEDED";
export const EDIT_USER_FAILED = "USER@EDIT_USER_FAILED";

export const DELETE_USER_INIT = "USER@DELETE_USER_INIT";
export const DELETE_USER_SUCCEDED = "USER@DELETE_USER_SUCCEDED";
export const DELETE_USER_FAILED = "USER@DELETE_USER_FAILED";


export const FETCH_FETCH_USER_EXTRA_DATA_INIT = "USER@FETCH_USER_INIT";
export const FETCH_USER_EXTRA_DATA_SUCCEDED = "USER@FETCH_USER_SUCCEDED";
export const FETCH_USER_EXTRA_DATA_FAILED = "USER@FETCH_USER_FAILED";


export const EXPORT_USER_INIT = "USER@EXPORT_USER_INIT";
export const EXPORT_USER_SUCCEDED = "USER@EXPORT_USER_SUCCEDED";
export const EXPORT_USER_FAILED = "USER@EXPORT_USER_FAILED";