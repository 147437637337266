import React from 'react'
import './tabStyle.scss'

const Tab = ({activeTab,label,onClick}) => {


const click = () => {
    onClick(label);
  } 
  
  let className = 'tab-list-item';
  let classNameLabel = 'tab-list-item-label'
  if (activeTab === label) {
    className += ' tab-list-active';
    classNameLabel = 'tab-list-item-label-active'
  }
  return (
    <div
    className={className}
    onClick={click}
  >
   <div className={classNameLabel}> {label} </div>
  </div>
);
}

export default Tab