import React, { createContext, useContext, useState, useCallback } from "react"
import { isEqual, isFunction } from "lodash"
import { IS_ACTIVE, IS_ACTIVE_INIT_VALUE } from "../store/constants"

const ProductsUIContext = createContext()

export function useProductsUIContext() {
    return useContext(ProductsUIContext)
}

export const ProductsUIConsumer = () => ProductsUIContext.Consumer

export function ProductsUIProvider({ ProductsUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState({ [IS_ACTIVE]: IS_ACTIVE_INIT_VALUE })
    const [ids, setIds] = useState([])
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams)
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams
            }

            return nextQueryParams
        })
    }, [])

    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        ...ProductsUIEvents
    }

    return (
        <ProductsUIContext.Provider value={value}>
            {children}
        </ProductsUIContext.Provider>
    )
}
