/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux"

// Reducers
import profileReducer from "./containers/profile/store/reducers"


import dashboardReducer from "./containers/home/store/reducers"
import dciReducer from "./containers/dci/store/reducers"
import CalendarReducer from './containers/calendar/store/reducers'
import listReducer from "./containers/list/store/reducers"
import specialtieReducer from "./containers/specialties/store/reducers"
import userReducer from "./containers/user/store/reducers"
import studentReducer from "./containers/student/store/reducers"
import trainerReducer from "./containers/trainer/store/reducers"
import trainingReducer from "./containers/training/store/reducers"
import faqReducer from "./containers/FAQ/store/reducers"
import ProductReducer from "./containers/product/store/reducers"
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default combineReducers({
  
  profile: profileReducer,
  dashboard: dashboardReducer,
  dci: dciReducer,
  list: listReducer,
  specialtie: specialtieReducer,
  user:userReducer,
  student:studentReducer,
  trainer:trainerReducer,
  training:trainingReducer,
  faq:faqReducer,
  Product:ProductReducer,
  calendar:CalendarReducer

})

