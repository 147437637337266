// list
export const CLEAR_TRAINER = "TRAINER@CLEAR_TRAINER";

export const FETCH_TRAINERS_INIT = "list@FETCH_TRAINERS_INIT";
export const FETCH_TRAINERS_SUCCEDED = "list@FETCH_TRAINERS_SUCCEDED";
export const FETCH_TRAINERS_FAILED = "list@FETCH_TRAINERS_FAILED";

export const CREATE_TRAINER_INIT = "TRAINER@CREATE_TRAINER_INIT";
export const CREATE_TRAINER_SUCCEDED = "TRAINER@CREATE_TRAINER_SUCCEDED";
export const CREATE_TRAINER_FAILED = "TRAINER@CREATE_TRAINER_FAILED";

export const FETCH_TRAINER_INIT = "TRAINER@FETCH_TRAINER_INIT";
export const FETCH_TRAINER_SUCCEDED = "TRAINER@FETCH_TRAINER_SUCCEDED";
export const FETCH_TRAINER_FAILED = "TRAINER@FETCH_TRAINER_FAILED";


export const EDIT_TRAINER_INIT = "TRAINER@EDIT_TRAINER_INIT";
export const EDIT_TRAINER_SUCCEDED = "TRAINER@EDIT_TRAINER_SUCCEDED";
export const EDIT_TRAINER_FAILED = "TRAINER@EDIT_TRAINER_FAILED";

export const DELETE_TRAINER_INIT = "TRAINER@DELETE_TRAINER_INIT";
export const DELETE_TRAINER_SUCCEDED = "TRAINER@DELETE_TRAINER_SUCCEDED";
export const DELETE_TRAINER_FAILED = "TRAINER@DELETE_TRAINER_FAILED";

export const FETCH_FETCH_TRAINER_EXTRA_DATA_INIT = "TRAINER@FETCH_TRAINER_INIT";
export const FETCH_TRAINER_EXTRA_DATA_SUCCEDED = "TRAINER@FETCH_TRAINER_SUCCEDED";
export const FETCH_TRAINER_EXTRA_DATA_FAILED = "TRAINER@FETCH_TRAINER_FAILED";


