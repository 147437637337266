import { ACTIONS, ENDPOINTS } from "../constants"
import { CALL_API, HTTP_METHODS } from "../../../../../../constants"


export const clearStore = () =>
({
  type: ACTIONS.CLEAR_SPECIALTIES
})


export const fetchSpecialties = (params) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_SPECIALTIESS_INIT,
      success: ACTIONS.FETCH_SPECIALTIESS_SUCCEDED,
      fail: ACTIONS.FETCH_SPECIALTIESS_FAILED
    },
    endpoint: ENDPOINTS.SPECIALTIESS,
    method: HTTP_METHODS.GET,
    params,
    auth: true
  }
})

export const createSpecialtie = (payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_SPECIALTIES_INIT,
      success: ACTIONS.CREATE_SPECIALTIES_SUCCEDED,
      fail: ACTIONS.FETCH_SPECIALTIES_FAILED
    },
    endpoint: ENDPOINTS.SPECIALTIES_CREATE,
    method: HTTP_METHODS.POST,
    auth: true
  }
})

export const editSpecialtie = ({ param }, payload) =>
({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.EDIT_SPECIALTIES_INIT,
      success: ACTIONS.EDIT_SPECIALTIES_SUCCEDED,
      fail: ACTIONS.EDIT_SPECIALTIES_SUCCEDED
    },
    endpoint: ENDPOINTS.SPECIALTIE.replace(":param", param),
    method: HTTP_METHODS.PATCH,
    auth: true
  }
})

export const activateSpecialtie = ({ param }) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.ACTIVATE_SPECIALTIES_INIT,
      success: ACTIONS.ACTIVATE_SPECIALTIES_SUCCEDED,
      fail: ACTIONS.ACTIVATE_SPECIALTIES_FAILED
    },
    endpoint: ENDPOINTS.SPECIALTIES_ACTIVATE.replace(":param", param),
    method: HTTP_METHODS.DELETE,
    auth: true
  }
})


export const deactivateSpecialtie = ({ param }) =>
({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.DEACTIVATE_SPECIALTIES_INIT,
      success: ACTIONS.DEACTIVATE_SPECIALTIES_SUCCEDED,
      fail: ACTIONS.DEACTIVATE_SPECIALTIES_FAILED
    },
    endpoint: ENDPOINTS.SPECIALTIES_DEACTIVATE.replace(":param", param),
    method: HTTP_METHODS.DELETE,
    auth: true
  }
})


export const fetchSpecialtie = ({ param }) =>{
  console.log({param})

return {
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_SPECIALTIES_INIT,
      success: ACTIONS.FETCH_SPECIALTIES_SUCCEDED,
      fail: ACTIONS.FETCH_SPECIALTIES_FAILED
    },
    endpoint: ENDPOINTS.SPECIALTIE.replace(":param", param),
    method: HTTP_METHODS.GET,
    auth: true
  }
}}