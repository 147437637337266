export const LOGIN = "/api/auth/login"

export const LOGOUT = "/auth/logout"

export const FORGOT_PASSWORD = "api/auth/reset-password/change"
export const RESET_PASSWORD = "/api/auth/reset-password"
export const FETCH_TOKEN = "/api/v1/authentication/check-token/:param"

export const REGISTER = "/api/auth/register"


export const VERIFY_USER = "/api/auth/reset-password/verify-user"
export const CHANGE_PASSWORD = "/api/auth/reset-password/verify-otp"

export const CHANGE_CURRENT_CENTER = "/api/current-center/change"
export const CHANGE_PHONE_NUMBER = "/api/change_phone"
export const CHANGE_PHONE_NUMBER_OTP = "/api/phone_otp"