import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"
import { lazy } from "react"
import { CREATE, LIST, UPDATE, VIEW } from "src/constants"
// import { VaccinationManagement } from "src/constants/ModulesPermissions"
import { MODULES_PERMISSIONS } from "src/constants";


const FAQspage = lazy(() => import("../components/card/FAQspage"))


const { SPECIALTIE } = MODULES_PERMISSIONS;

export const FAQsList = {
  path: "/FAQ",
  component: FAQspage,
  can: SPECIALTIE.permissions[LIST]
}



const path = routes.admin.path

export default combinePathRoutes({ path }, {
  FAQsList,

})
