/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react"
import { Modal } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { FormModal, ModalProgressBar } from "../../../../../../components/partials/controls"
// import { deactivateService, fetchServices } from "../../store/actions"
import { fetchSpecialties, deactivateSpecialtie, createFAQ, fetchFAQs } from "../../store/actions"
import { useFAQsUIContext } from "../../context/FAQsUIContext"
import DynamicForm from "src/components/partials/controls/forms/dynamic-form-without-store/DynamicForm"

import {FAQfields} from "./fields/faqfieldes"
export const AjoutFAQDialog = ({ params, show, onHide,intl }) => {
  // Services UI Context
  const FAQsUIContext = useFAQsUIContext()

  const fieldsFr = FAQfields({ intl })
  // Services Redux state
  const dispatch = useDispatch()
  const { isLoading, success ,error=false} = useSelector(
    (state) => ({ isLoading: state.admin.faq.isLoading, success: state.admin.faq.success ,error:state.admin.faq.error}),
    shallowEqual
  )

  
  

  const onCreateFAQ = (values) => {
    // server request for deleting service by id
    dispatch(createFAQ(values))
    onHide()
  }

  return (
    <FormModal
      success={success}
      onHide={() => onHide()}
      show={show}
      size="md"
      subtitle={<FormattedMessage id="FAQ.TITLE_AJOUT" />}
    >
      {({ saveRef }) => (
         <DynamicForm
         initialValues={{}}
         onSubmit={onCreateFAQ}
         error={error}
         clearValuesAfterSubmit={success}
         fields={fieldsFr}
         saveRef={saveRef}
       >
       
     </DynamicForm>
      )}
    </FormModal>
  )
}


export default AjoutFAQDialog 
