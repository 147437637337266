import React, { useEffect } from "react";
import { injectIntl } from "react-intl";

import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { fetchListExtraData } from "./../../store/actions";

import { listFieldsAr, listFieldsFr } from "./fields/listFields";
import routes from "../../routes/pages";
import {
  DisplayItems,
  LanguageTab,
  RenderItems,
  ShowView,
  ShowViewReferentiel,
} from "../../../../../../components/partials";

const PersonalData = ({ params, history, goBackToList, intl }) => {
  const fieldsAr = listFieldsAr({ intl });
  const fieldsFr = listFieldsFr({ intl });

  const dispatch = useDispatch();

  const { isFetching, error, item } = useSelector(
    (state) => ({
      isFetching: state.admin.list.isFetching,
      item: state.admin.list.listExtraData,
      error: state.admin.list.error,
    }),
    shallowEqual
  );

  const goToEdit = () => {
    history.push(routes.listEdit.path.replace(":param", params.param));
  };
  useEffect(() => {
    dispatch(fetchListExtraData(params));
    // eslint-disable-next-line
  }, []);

  return (
    <ShowViewReferentiel
      title={intl.formatMessage({ id: "LIST.SHOW" })}
      goBackTo={goBackToList}
      print={false}
      //printURL={ENDPOINTS.EXPORT_PARTNERSHIP.replace(":param", params.param)}
      goToEdit={goToEdit}
    >
      <LanguageTab>
        {({ isFr, isAr }) => (
          <DisplayItems error={error} isFetching={isFetching} object={item}>
            <RenderItems fields={fieldsAr} show={isAr} />
            <RenderItems fields={fieldsFr} show={isFr} />
          </DisplayItems>
        )}
      </LanguageTab>
    </ShowViewReferentiel>
  );
};

export default injectIntl(PersonalData);
