import { ACTIONS } from "../constants"


const initialState = { 
  sessionsCount:0,
  trainercount:0,
  certifecount:0,
  sessions:[],
  session:{},
  adminCount:0,
  isFetching: false, 
  isLoading: false,
  hasMore: true, 
  error: null
}


export default (state = initialState , action) => {
  const { payload, type } = action
  switch (type) {
 

  
    case ACTIONS.FETCH_SESSIONS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        sessionsCount: count,
        sessions: payload,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_SESSIONS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.FETCH_SESSION_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_SESSION_SUCCEDED: {
      return {
        ...state,
        session: payload,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_SESSION_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }
    case ACTIONS.FETCH_USERS_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_USERS_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        adminCount: count,
        users: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_USERS_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    case ACTIONS.EDIT_USER_INIT: {
      return {
        ...state,
        isLoading: true,
        error: null,
        success: initialState.success,
      };
    }
    case ACTIONS.FETCH_CERTIFE_INIT: {
      return { ...state, isFetching: true, error: null };
    }
    case ACTIONS.FETCH_CERTIFE_SUCCEDED: {
      const { count, results } = payload;
      return {
        ...state,
        certifecount: count,
        certife: results,
        isFetching: false,
        error: null,
      };
    }
    case ACTIONS.FETCH_CERTIFE_FAILED: {
      return { ...state, isFetching: false, error: payload };
    }

    default: {
      return state
    }
  }
}
