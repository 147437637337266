import { DEACTIVATE, ACTIVATE} from "../../../../../constants"
import { DCI } from "src/constants/AppPermissions"
import { VaccinationManagement } from "src/constants/ModulesPermissions"
import routes from "src/routes"
import { lazy } from "react"
import { combinePathRoutes } from "src/helpers"
import {DciActivateDialog} from "../components/dialog/DciActivateDialog"
import {DciDeactivateDialog} from "../components/dialog/DciDeactivateDialog"
import {pages} from "./index"


export const dciActivate = {
  path: "/activate/:param",
  component: DciActivateDialog,
  can:  VaccinationManagement.module[DCI].permissions[ACTIVATE]
}

export const dciDeactivate = {
  path: "/deactivate/:param",
  component: DciDeactivateDialog,
  can:  VaccinationManagement.module[DCI].permissions[DEACTIVATE]
}

const path = pages.dciList.path

export default combinePathRoutes({ path }, {
    dciActivate,
    dciDeactivate
})
