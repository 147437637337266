export const FETCH_FAQS_INIT = "FAQ@FETCH_FAQS_INIT"
export const FETCH_FAQS_SUCCEDED = "FAQ@FETCH_FAQS_SUCCEDED"
export const FETCH_FAQS_FAILED = "FAQ@FETCH_FAQS_FAILED"


export const CLEAR_FAQS = "FAQ@CLEAR_FAQS"

export const FETCH_FAQ_INIT = "FAQ@FETCH_FAQ_INIT"
export const FETCH_FAQ_SUCCEDED = "FAQ@FETCH_FAQ_SUCCEDED"
export const FETCH_FAQ_FAILED = "FAQ@FETCH_FAQ_FAILED"

export const CREATE_FAQS_INIT = "FAQ@CREATE_FAQS_INIT"
export const CREATE_FAQS_SUCCEDED = "FAQ@CREATE_FAQS_SUCCEDED"
export const CREATE_FAQS_FAILED = "FAQ@CREATE_FAQS_FAILED"


export const EDIT_FAQS_INIT = "FAQ@EDIT_FAQS_INIT"
export const EDIT_FAQS_SUCCEDED = "FAQ@EDIT_FAQS_SUCCEDED"
export const EDIT_FAQS_FAILED = "FAQ@EDIT_FAQS_FAILED"

export const DELETE_FAQS_INIT = "FAQ@DELETE_FAQS_INIT"
export const DELETE_FAQS_SUCCEDED = "FAQ@DELETE_FAQS_SUCCEDED"
export const DELETE_FAQS_FAILED = "FAQ@DELETE_FAQS_FAILED"


export const DEACTIVATE_FAQS_INIT = "FAQ@DEACTIVATE_FAQS_INIT"
export const DEACTIVATE_FAQS_SUCCEDED = "FAQ@DEACTIVATE_FAQS_SUCCEDED"
export const DEACTIVATE_FAQS_FAILED = "FAQ@DEACTIVATE_FAQS_FAILED"

export const ACTIVATE_FAQS_INIT = "FAQ@ACTIVATE_FAQS_INIT"
export const ACTIVATE_FAQS_SUCCEDED = "FAQ@ACTIVATE_FAQS_SUCCEDED"
export const ACTIVATE_FAQS_FAILED = "FAQ@ACTIVATE_FAQS_FAILED"
