import React, { useState } from 'react'
import moment from 'moment'

import { getLang } from './../../../../i18n'
import { FR, AR } from './../../../../constants'

import CalendarEvent from './CalendarEvent'

import FullCalendar from '@fullcalendar/react'

// plugin
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import arTnLocale from '@fullcalendar/core/locales/ar-tn'
import frLocale from '@fullcalendar/core/locales/fr'

const LANGUAGES = {
  [FR]: frLocale,
  [AR]: arTnLocale
}

const Appointment = ({ isFetching, dateClick, eventClick, onWeek, fetchEvents, loadEvents, ...props }) => {
  const [prevDateRange, setPrevDateRange] = useState({})

  const loadEventsCallback = (info, successCallback, failureCallback) => {
    if (info.startStr !== prevDateRange.startStr && info.endStr !== prevDateRange.endStr && !isFetching) {
      const start = moment(info.start); const end = moment(info.end)
      fetchEvents({ start: start.format('DD/MM/YYYY'), end: end.format('DD/MM/YYYY') })
      setPrevDateRange(info)
    }

    loadEvents(successCallback, failureCallback)
  }

  return (
    <div className='fc fc-ltr fc-unthemed'>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        eventContent={(eventInfo) => <CalendarEvent eventInfo={eventInfo} />}
        dateClick={dateClick}
        initialView='dayGridMonth'
        editable={false}
        selectable
        locale={LANGUAGES[getLang()]}
        selectMirror
        eventClick={eventClick}
        events={loadEventsCallback}
        weekends
        {...props}
        lazyFetching
      />
    </div>
  )
}

export default Appointment
