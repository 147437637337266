import {getEnv} from "../helpers/EnvHelpers"

export const API_BASE_URL = getEnv("REACT_APP_API_BASE_URL")
export const API_BACK_OFFICE_URL = getEnv("REACT_APP_BACK_OFFICE_API_BASE_URL")
export const API_EXTERNAL_URL = getEnv("REACT_APP_API_EXTERNAL_URL")
export const RECAPCHA_SITE_KEY = getEnv("REACT_APP_RECAPCHA_SITE_KEY")
// export const API_BACK_BASE_URL = getEnv("REACT_APP_API_BACK_BASE_URL")

export const CALL_API = "middleware@CALL_API"

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
}
