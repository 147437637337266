import { combinePathRoutes } from "src/helpers"
import routes from "src/routes"
import { lazy } from "react"
import { CREATE, LIST, UPDATE, VIEW } from "src/constants"
// import { VaccinationManagement } from "src/constants/ModulesPermissions"
import { MODULES_PERMISSIONS } from "src/constants";



const Productspage = lazy(() => import("../components/card/Productspage.js"))
const ProductShow = lazy(() => import("../components/card/ProductShow.js"))

const { SPECIALTIE } = MODULES_PERMISSIONS;

export const ProductList = {
  path: "/products",
  component: Productspage,
  can: SPECIALTIE.permissions[LIST]
}
export const Product = {
  path: "/product/:param/show",
  component: ProductShow,
  can: SPECIALTIE.permissions[LIST]
}



const path = routes.admin.path

export default combinePathRoutes({ path }, {
  ProductList,
  Product,

})
